import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changeLocationAction } from "../../redux/themeDucks";

const Investors = () => {
  const dispatch = useDispatch();
  const settings = useSelector((store) => store.settings);

  return (
    <section className="investors">
      <div className="container-fluid">
        <h4 className="title-1">Inversores</h4>
        <div className="items-wrapper">
          <div className="col-lg-4s">           
            <InvestorItem 
              icon="icon-icon-dollar"
              title="Grandes inversores"
              description="Inversores que participen aportando capital para la compra de la
              tierra o para desarrollo de proyectos, para maximizar su
              rentabilidad."
            />
          </div>
          <div className="col-lg-4s">
            <InvestorItem 
              icon="icon-user"
              title={<>Usuario finales <br /> e inversores minoristas</>}
              description="Empresas o particulares que cuenten con capital para sumarse a
              algunos de los proyectos liderados por la desarrolladora, ya sea
              como inversión como para uso propio."
            />
          </div>
          <div className="col-lg-4s">
            <InvestorItem 
              icon="icon-land"
              title="Dueños de tierra"
              description="Inversores que participen aportando capital para la compra de la
              tierra o para desarrollo de proyectos, para maximizar su
              rentabilidad."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

function InvestorItem({title, description, icon}){
  const dispatch = useDispatch();
  const settings = useSelector((store) => store.settings);

  return (
   <div>
      <div className="item shadow-2">
        <div>
          {/* <img src={image} alt={title} width="40px" /> */}
          <i className={icon}></i>
          <h5 className="pt-4">
            {title}
          </h5>
          <p >
            {description}
          </p>
        </div>

        <Link className="contact-link" to={settings.base_url + "contacto"} onClick={() => dispatch(changeLocationAction("contacto"))}  >
          <button className="btn-rounded">
            Contactanos
          </button>
        </Link>
      </div>
   </div>
  )
}

export default Investors;
