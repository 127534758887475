import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getListFilters,getPropertiesAction,clearPropertyAction } from "../redux/propertiesDucks";

import { useSelector } from "react-redux";

// -- Componentes -- //

import Main from "../components/propiedades/main";
import Grid from "../components/propiedades/grid";

function Resultados() {
  const { data } = useSelector((store) => store.properties.listFilters);
  const theme = useSelector((store) => store.theme);
  const settings = useSelector((store) => store.settings);
  const dispatch = useDispatch();


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = settings.seo.title
  }, [data]);

  useEffect(() => {
    dispatch(clearPropertyAction());
  },[])

  return (
    <div className={"Resultados " + (theme.dark ? 'dark' : '')}>
      {data ? 
      <>
        <Main />
        <Grid></Grid> 
      </>
      :
        <div className="loading-section">
          <div className="lds-dual-ring"></div>{" "}
        </div>
      }
    </div>
  ) 
}

export default Resultados;
