import { useEffect } from "react"

import Main from '../components/mapa/main'

const Mapa = () =>{
    return  (
        <>
            <Main />
        </>
    )
}

export default Mapa