import { Provider } from "react-redux";
import generateStore from "./redux/store";

import {
  BrowserRouter as Router,
  Switch,
  withRouter,
  Route,
} from "react-router-dom";

// Bool para producción-local
const production = false;
let routePath = "";
if (production) {
  routePath = "/clientes/compania-carilo";
}

//Vistas
import Inicio from "./views/home.jsx";
import Propiedades from "./views/properties.jsx";
import Propiedad from "./views/property.jsx";
import Emprendimientos from "./views/developments.jsx";
import Emprendimiento from "./views/development.jsx";
import Contacto from "./views/contact.jsx";
import Tasaciones from "./views/tasaciones.jsx";
import Nosotros from "./views/about.jsx";
import Favoritos from "./views/favorites.jsx";
import Info from "./views/info.jsx";
import Mapa from "./views/mapa.jsx";

import RedirectViewId from "./views/redirectViewId.jsx";
import redirectViewHome from "./views/redirectViewHome.jsx";
import RedirectViewCode from "./views/redirectViewCode.jsx";

// Componentes
import Header from "./components/header.jsx";
import Head from "./components/head.jsx";
import ScrollToTop from "./components/funcScrollToTop.jsx";
import Footer from "./components/footer.jsx";

function App() {
  const store = generateStore();

  return (
    <Router>
      <ScrollToTop />
      <Provider store={store}>
        <Head />
        <Header />
        <Switch>
          <Route path={routePath + "/"} exact>
            <Inicio />
          </Route>
          <Route path={routePath + "/ventas"} component={Propiedades} />
          <Route path={routePath + "/alquileres"} component={Propiedades} />
          <Route path={routePath + "/alquileres-temporarios"} component={Propiedades} />
          <Route path={ routePath + "/propiedad/:operation/:type/:location/:id/:address"} exact component={Propiedad} />
          <Route path={routePath + "/tokko/:id"} exact component={RedirectViewId} />
          <Route path={routePath + "/web"} exact component={redirectViewHome} />
          <Route path={routePath + "/code/:code"} exact component={RedirectViewCode} />
          <Route path={routePath + "/emprendimientos"} exact component={Emprendimientos} />
          <Route path={routePath + "/emprendimiento/:id"} exact component={Emprendimiento} />
          <Route path={routePath + "/tasaciones"} exact component={Tasaciones} />
          <Route path={routePath + "/tasaciones/:step"} exact component={Tasaciones} />
          <Route path={routePath + "/nosotros"} exact component={Nosotros} />
          <Route path={routePath + "/contacto"} exact component={Contacto} />
          <Route path={routePath + "/favoritos"} exact component={Favoritos} />
          <Route path={routePath + "/info"} exact component={Info} />
          <Route path={routePath + "/mapa"} exact component={Mapa} />
        </Switch>
        <Footer />
      </Provider>
    </Router>
  );
}

export default withRouter(App);
