import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeThemeAction } from "../../redux/themeDucks";
import { redux_updateOrders,getPropertiesAction,clearPropertiesAction } from "../../redux/propertiesDucks";
import propertiesReducer from "../../redux/propertiesDucks";
import { useHistory } from "react-router";
import BreadCrumbs from "../breadcrumbs";
import { useRef } from "react";

const Main = () => {
  const router = useHistory()
  const dispatch = useDispatch()
  const [location, setLocation] = useState(window.location.pathname.split("/"));
  const [orders,setOrders] = useState({order:'desc',order_by:'price'})
  const theme = useSelector((store) => store.theme);
  const settings = useSelector(store => store.settings)
  const listed = useSelector(store => store.properties.listed)
  const {data} = useSelector(store => store.properties.properties)

  const selectorOrder = useRef()

  useEffect(() => {
    setLocation(window.location.pathname.split("/"));
  }, []);

  function toggleFilters() {
    $("#filter").toggleClass("open");
    $(".fixed-bar").toggleClass("d-none")
    $("html").toggleClass("overflow-hidden-mobile");
  }

  function checkView(){
    // if(window.location.pathname === settings.base_url + 'ventas'){
    if(window.location.pathname.includes('ventas')){
      return 'Venta'
    }
    return 'Alquiler'
  }

  function traductorSelect(){
    switch(selectorOrder.current.value){
      case '0': setOrders({order:'desc',order_by:'price'}); break;
      case '1': setOrders({order:'asc',order_by:'price'}); break;
      case '2': setOrders({order:'desc',order_by:'age'}); break;
      case '3': setOrders({order:'asc',order_by:'age'}); break;
      case '4': setOrders({order:'desc',order_by:'roofed_surface'}); break;
      case '5': setOrders({order:'asc',order_by:'roofed_surface'}); break;
    }
  }

  useEffect(() =>{
    $(document).ready(function() {
      $('#resizing_select').change(function(){
        $("#width_tmp_option").html($('#resizing_select option:selected').text()); 
        $(this).width($("#width_tmp_select").width());  
      });
    });

  }) 

  useEffect(() => {
    dispatch(redux_updateOrders(orders))
  },[orders])

  return  (
    <section className="main">
      <div className="container-fluid">
        <button className="btn-filters d-block d-lg-none" onClick={() => toggleFilters()}>
          <i className="icon-filter me-3"></i>
          Filtros
        </button>
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-md-center align-items-md-end">
          <div>
            <BreadCrumbs
              props={[
                { name: "Home", route: settings.base_url, location: "" },
                {
                  name: checkView(),
                  route: settings.base_url + theme.location,
                  location: theme.location,
                  active: true,
                },
              ]}
            />
            
            <h5 className="mt-5 dark-blue">{data ? data.meta.total_count +  '+ resultado total de la búsqueda' : 'Cargando resultados totales...'}</h5>
            <h2 className="title-1">
              {" "}
              <div className="d-inline-block dark-blue">
                Propiedades en 
              </div>{" "}
              <span className="regionName" style={{fontWeight:"inherit"}}> {checkView()} </span>
            </h2>
          </div>
          <div className="align-self-sm-end d-flex flex-column align-items-sm-end mb-3 dark-blue" >
            {/* <span className="d-lg-flex justify-content-end d-none text-end">
              <button onClick={() => dispatch(changeThemeAction(!theme.dark))} className="ms-2 btn-square cursor-pointer active">
                <i className="icon-sun-theme"></i>
              </button>{" "}
              <button onClick={() => toggleFilters()} className="btn-square cursor-pointer">
                <i className="icon-filter"></i>
              </button>
            </span> */}
            <div>
              <div className="listing-type d-none d-lg-flex">
                <div>
                  Ver:
                </div>
                <i className={`icon-mosaico ${!listed ? "active"  : ""}`} onClick={()=>{dispatch({type:"CHANGE_TYPE",payload:false})}}></i>
                <i className={`icon-lista ${listed ? "active"  : ""}`} onClick={()=>{dispatch({type:"CHANGE_TYPE",payload:true})}}></i>
              </div>
            </div>

            <div className="orderby d-flex align-items-end order-mobile text-end  mb-0">
              <div className="d-lg-inline-block d-none fw-bold"> Ordenar por: </div>
              <div className="select-wrapper">
                <select ref={selectorOrder} onChange={() => traductorSelect()} name="orderby" id="resizing_select">
                  <option value="0">Mayor precio</option>
                  <option value="1">Menor precio</option>
                  <option value="2">Mayor antigüedad</option>
                  <option value="3">Menor antigüedad</option>
                  <option value="4">Mayor superficie</option>
                  <option value="5">Menor superficie</option>
                </select>
                <i className="icon-arrow"></i>
              </div>
              <select id="width_tmp_select">
                <option id="width_tmp_option"></option>
              </select>
            </div>
          </div>
        </div>
        { data ?
            data.meta.total_count === 0 ?
            <div className="col-12">
              <span className="not-results-message">En estos momentos no hay resultados para tu búsqueda.</span>
            </div>
             : ''
          :''}
      </div>
    </section>
  )
};

export default Main;
