import React , {useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
// -- Componentes -- //

import Main from '../components/favoritos/main'

function Favoritos(){

    const settings = useSelector((store) => store.settings);
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = settings.seo.title
        dispatch({type:"CHANGE_TYPE",payload:false})
      }, []);
    return(
        <div className="favoritos">
            <Main />
        </div>
    )
}

export default Favoritos
