import {useState} from 'react'
import {Link} from "react-router-dom";
import {useSelector} from 'react-redux'
import {shortLocation, getFeatured} from '../../functions/functions_dev'
import { useEffect } from 'react';

const Main = () => {
    const [active,setActive] = useState(0)
    const {data} = useSelector(store => store.developments.developments)
    const [featured,setFeatured] = useState() 
    const settings = useSelector(store => store.settings)


    useEffect(()=>{
        if(data){
            setFeatured(getFeatured(data))
        }
    },[data])

    const checkExist = () => {
        return featured.length;
    }
 

    return featured ? (
        <section className="main">
            <div className="container-fluid">
                <div className=" content-image shadow-2">
                    
                    {
                    checkExist() > 0 ? 
                        getFeatured(data).map((item,index) =>(
                            <img className={'animate__animated animate__fadeIn animate__fast ' + (index == active ? 'd-block' : 'd-none')} src={(item.photos).find(element => element.is_front_cover === true).original} alt="" />
                        ))
                        :
                        <img className={'animate__animated animate__fadeIn animate__fast '} src="https://i0.wp.com/elfutbolito.mx/wp-content/uploads/2019/04/image-not-found.png?ssl=1" alt="" />
                    }
                </div>
            </div>
            <div className="container-fluid full-container">
                <div className="content-slider d-flex justify-content-start align-items-center">
                    <div className="item active shadow-2 d-flex flex-column align-items-start animate__animated animate__fadeIn animate__fast">
                        <div>
                            <h3>{checkExist() > 0 ?  featured[active].address : 'Información no disponible.'}</h3>
                            <h5 className="location d-flex align-items-center">
                                <i className="icon-location-icon me-2"></i>
                                {checkExist() > 0 ? shortLocation(featured[active].location.full_location,2) : 'Información no disponible.'} <span className="line-location d-inline-block mx-3"></span> {checkExist() > 0 ? shortLocation(featured[active].location.full_location,1) : ''}
                            </h5>
                            <h5 className="surface mb-2">{checkExist() > 0 ? featured[active].surface : 'Información no disponible.'}</h5>
                            <Link className="mt-auto" to={(settings.base_url + "emprendimiento/") + (checkExist() > 0 ? featured[active].id : '')}>VER MÁS</Link>
                        
                        </div>
                        <div className="arrows">
                            {
                                checkExist() > 0 ?
                                    <> 
                                    {
                                        active < getFeatured(data).length -1  ? (<div onClick={() => setActive(active + 1)} className="next btn-medium d-flex align-items-center"><i className="icon-arrow"></i></div>) : '' +  
                                        active > 0 ? <div onClick={() => setActive(active - 1)} className="back btn-medium d-flex align-items-center"><i className="icon-arrow"></i></div> : ''
                                    }
                                </>
                                :''}

                        </div>
                    </div>
                    {/* {getFeatured(data).map((item,index)=> (
                        active < index ? 
                        <div key={index} className="item d-none d-lg-block">
                            <h3><span>{item.address}</span></h3>
                        </div>
                        :''
                    ))} */}
                </div>
            </div>
        </section>
    ) 
    : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Main