import Card from "./../property-card";
import { useSelector, useDispatch } from "react-redux";
import { getAdd_PropertiesAction } from "../../redux/propertiesDucks";
import { useEffect } from "react";

const Results = () => {
  const dispatch = useDispatch();
  const listed = useSelector((store) => store.properties.listed);
  const properties = useSelector((store) => store.properties.properties);
  const theme = useSelector((store) => store.theme);
  const offset = useSelector((store) => store.properties.offset);

  const checkStatusButton = () => {
    if (properties.data) {
      if (
        properties.data.objects.length > 0 &&
        properties.data.objects.length < properties.data.meta.total_count
      ) {
        return true;
      }
      return false;
    }
  };

  return (
    <div className="px-0 w-100">
      <div className="results ">
        <div className="row no-gutters justify-content-between ">
        
          {properties.data ?
           properties.data.objects.map((property, index) =>
              (
                <div className={(!listed ? "col-lg-6" : "col-lg-12 div-card-list") + " px-lg-4 mb-4 mb-lg-5"}>
                <Card property={property} />
              </div>
              )
          ):
          <div className="loading-section"><div className="lds-dual-ring"></div> </div>}
          <div
            className={
              "col-12 text-center " +
              (checkStatusButton() ? "d-block" : "d-none")
            }
          >
            <button
              onClick={() => dispatch(getAdd_PropertiesAction())}
              className="w-auto border-none mx-auto my-5 btn-medium d-inline-block"
            >
              Mostrar más
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
