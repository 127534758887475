import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changeLocationAction } from "../../redux/themeDucks";
import Image from "../../assets/images/nosotros.png";
import QuotIcon from "../../assets/images/icons/quot-icon.svg";
import LogoRotation from "../LogoRotation";

function About() {
  const dispatch = useDispatch();
  const settings = useSelector((store) => store.settings);

  return (
    <div className="about text-center text-lg-start">
      <div className="container-fluid position-relative">
        <div className="row">
          <div className="col-12 d-none d-lg-block">
              <LogoRotation />
          </div>
          <div className="col-lg-6 d-flex justify-content-sm-center" >
            <img
              src={Image}
              alt="habitació"
              className="about-image"
              width="400px"
            />
          </div>
          <div className="description col-lg-6 ps-0 d-lg-flex flex-lg-column justify-lg-content-between position-relative">
            <div className="d-flex div-pad">
              <div>
                <h2 className="title-1 dark-blue">
              
                “Somos un equipo <br /> experimentado <br /> en el mercado{" "}
                  <br /> inmobiliario”
                </h2>
                <p className=" paragraph-1 " style={{ maxWidth: 550 }}>
                  En los años acumulados a través de nuestra experiencia en el sector inmobiliario, comprendimos que esta actividad va más allá de las necesidades, expectativas, urgencias, proyectos e ilusiones del cliente, ya sea el caso de un comprador, inversor, inquilino o propietario.
                </p>
                <Link
                  to={settings.base_url + "nosotros"}
                  onClick={() => dispatch(changeLocationAction("nosotros"))}
                >
                  <button className="btn-rounded">Más sobre nosotros</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default About;
