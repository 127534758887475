import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams,useHistory } from "react-router"
import { getPropertyAction } from "../redux/propertiesDucks"
import {shortDirection} from '../functions/functions_property'
import { Link } from "react-router-dom"

const Redirect = () =>{
    const history = useHistory()
    const settings = useSelector(store => store.settings)

    return  (
        <div>
            {history.push((settings.base_url))}        
        </div>
    )
}

export default Redirect