import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import BreadCrumbs from "../breadcrumbs";
import {
  getLocationProperty,
  getPrice,
  searchTour,
  searchYoutube,
  get_short_desc,
  addFavorites,
  getfavorites,
} from "../../functions/functions_property";
import { updateFields, formTokko } from "../../redux/contactDucks";
import {
  getFavoritesAction,
  clearFavoritesAction,
} from "../../redux/propertiesDucks";

import Map from "../map";
import BoxPhotos from "./boxphotos";
import Contact from "../Contact";
import { getWhatsapp } from "../../functions/functions_contact";

const Data = () => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const settings = useSelector((store) => store.settings);
  const property = useSelector((store) => store.properties.property).data;
  const [listFavorites, setListFavorites] = useState(
    getfavorites("prop", settings.short_name)
  );

  const [max_caracters, setMax_caracter] = useState(700);
  const [hidden, setHidden] = useState(true);
  const [text, setText] = useState("");
  const [datos, setDatos] = useState({
    nombre: "",
    apellido: "",
    email: "",
    text: "",
  });
  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };
  const sendForm = (event) => {
    event.preventDefault();
    dispatch(formTokko("Propiedad", property.fake_address, property.id));
    document.getElementById("formContact").reset();
    setText("¡Mensaje enviado correctamente!");
  };
  const checkActive = (id) => {
    setListFavorites(getfavorites("prop", settings.short_name));
    dispatch(clearFavoritesAction());
    for (const iterator of getfavorites("prop", settings.short_name)) {
      //Por cada uno de los favoritos, traigo su respectiva data de la api
      dispatch(getFavoritesAction(iterator));
    }
  };

  useEffect(() => {
    const update = () => {
      dispatch(updateFields(datos));
    };
    update();
  }, [datos]);

  const checkAtributes = () => {
    if(property.room_amount > 0 || property.suite_amount > 0 || property.toilet_amount > 0 || property.bathroom_amount > 0 || property.roofed_surface > 0){
      return true
    }
    return false
  }

  return property ? (
    <section className="data">
      <div className="container-fluid">
        <div className="row align-items-start">
          <div className="col-12">
            <BreadCrumbs
              props={[
                { name: "Home", route: "", location: "" },
                {
                  name: "Venta",
                  route: settings.base_url + "ventas",
                  location: "ventas",
                },
                {
                  name: property.type.name,
                  route:
                    settings.base_url +
                    "ventas/" +
                    property.type.name
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, ""),
                  location: "ventas",
                },
                {
                  name: property.fake_address,
                  route: "",
                  location: "",
                  active: true,
                },
              ]}
            />
          </div>
          <div className="col-12 tags d-flex d-lg-none align-items-start d-none">
            {property.operations.map((item) => (
              <div className={"tag me-3 " + item.operation_type}>
                {item.operation_type}
              </div>
            ))}
            <div className="tag primary-tag ms-3 text-uppercase">
              {property.type.name}
            </div>
          </div>
          <div className="col-lg-7">
            <h1 className="title-1 text-lg-start text-md-start " >
              {property.fake_address}
              <br />
              <div className="location mt-3 ">
                <i className="icon-location"></i>{" "}
                {getLocationProperty(property.location.full_location)}{" "}
              </div>
            </h1>
          </div>
          <div className="col-lg-5 div-iconos d-flex justify-content-end overflow-hidden position-relative mt-0 mt-md-4 text-center text-md-start">
            <button
              className={
                "btn-medium-1 d-flex align-items-center   " +
                (searchTour(property.videos) && property.videos.length > 0
                  ? "d-block"
                  : "d-none")
              }
              style={{ padding: "8px 15px" }}
            >
              <i className="icon-tour-360 pe-2"></i> TOUR VIRTUAL
            </button>
            <div className="d-flex">
              <div className="d-flex align-items-center overflow-hidden">
                <div
                  className={
                    "d-flex align-items-center social-block " +
                    (hidden ? "hidden" : "")
                  }
                >
                  <a
                    href={
                      "mailto:" +
                      "?subject=" +
                      property.fake_address +
                      "&body=" +
                      window.location
                    }
                    style={{ padding: "10px" }}
                    className="btn-medium-dark d-flex align-items-center justify-content-center"
                  >
                    <i className="icon-ico-sendmail" style={{ fontSize: 20 }}></i>
                  </a>
                  <a
                    href={getWhatsapp(
                      "",
                      "Hola, quería compartirte esta propiedad de " + settings.name +
                        " " +
                        window.location +
                        ")"
                    )}
                    target="_blank"
                    className="btn-medium-dark  ms-2 d-flex align-items-center justify-content-center"
                    style={{ fontSize: 20, padding: "10px 10px" }}
                  >
                    <i className="icon-whatsapp"></i>
                  </a>
                </div>

                <button
                  onClick={() => setHidden(!hidden)}
                  className="btn-medium-dark  d-flex justify-content-center align-items-center"
                  style={{ marginLeft:15, fontSize: 20, padding: "10px 15px" }}
                >
                  <i className="icon-share"></i>
                </button>
              </div>
              {/* <button className="btn-square small d-flex justify-content-center align-items-center">
                <i className="icon-solid-like"></i>
              </button> */}
              <button
                onClick={() =>
                  addFavorites(property.id, "prop", settings.short_name) +
                  checkActive()
                }
                className={
                  "btn-medium-dark small d-flex justify-content-center align-items-center " +
                  (listFavorites.find(
                    (element) => element.toString() === property.id.toString()
                  )
                    ? "active"
                    : "")
                }
                style={{ fontSize: 20, padding: "10px" }}
              >
                <i className="icon-solid-like"></i>
              </button>
            </div>

          </div>
        </div>
        <BoxPhotos />
        <div className={"row mt-lg-5 mt-3" + (checkAtributes() ? ' ' : ' align-items-start')}>
          {/* //Descripción */}
          <div className="col-lg-8">
            <h2 className="title-1 dark-blue">Descripción de la propiedad</h2>
            <div className="tags  d-flex align-items-start mt-0 mt-md-5">
              {property.operations.map((item) => (
                <div className={"tag me-3 " + item.operation_type}>
                  {item.operation_type}
                </div>
              ))}
              <div className="tag primary-tag">{property.type.name}</div>
            </div>
            <p className="dark-blue">
              {get_short_desc(property.description, max_caracters).map((element) => (
                <>
                  {element}
                  <br />
                </>
              ))}
                <a
                className="cursor-pointer see_description text-dark dark-blue"
                onClick={() =>
                  max_caracters == 700
                    ? setMax_caracter(9999)
                    : setMax_caracter(700)
                }
              >
                {property.description.length > 700
                  ? max_caracters == 700
                    ? "Ver más"
                    : "Ver menos"
                  : ""}
              </a>
              </p>
          </div>
          {/* //Precio */}
          <div className="property-data col-lg-4">
            <div className="col-lg-6 text-lg-end">
              <div className="content-price px-5">
                {property.operations.map((item) => (
                  <h3
                    className={
                      item.operation_type +
                      (property.web_price ? "" : " min-size")
                    }
                  >
                    {property.web_price
                      ? getPrice(item.prices[0])
                      : "Precio a consultar"}
                  </h3>
                ))}
              </div>
            </div>

            <div>
              <div className={"col-lg-6 property-atributes" + (checkAtributes() ? '' : ' d-none ')}>
                <div className={"d-flex flex-wrap justify-content-between align-items-start"} style={{ gap: "1.5rem 0" }}>
                    <div className="d-flex align-items-start w-100 mt-0">
                      <div className="div me-lg-4 me-2">
                        {property.room_amount > 0 || property.suite_amount > 0? (
                        <div className="item-extra d-flex align-items-start px-3 mt-0 mb-0 w-100">
                          {property.room_amount > 0 ||
                          property.suite_amount > 0 ? (
                            <div className="">
                              <i className="icon-bed1 mt-lg-1 d-block me-3"></i>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="d-flex flex-column">
                            <span className="text-nowrap mb-3">
                              {property.suite_amount > 0
                                ? property.suite_amount +
                                  " Dormitorio" +
                                  (property.suite_amount > 1 ? "s" : "")
                                : " "}
                            </span>
                            <span className="text-nowrap mb-3">
                              {property.room_amount > 0
                                ? property.room_amount +
                                  " Ambiente" +
                                  (property.room_amount > 1 ? "s" : "")
                                : ""}
                            </span>
                          </div>
                        </div>
                      ):null}
                        {/* // Baños */}
                        {property.toilet_amount > 0 || property.bathroom_amount > 0 ? (
                          <div className="item-extra d-flex align-items-start px-3 mt-lg-3 mb-2">
                            {property.bathroom_amount > 0 && (
                              <div className="pt-lg-1">
                                <i className="icon-bath me-4"></i>
                              </div>
                            )}
                            <div className="d-flex flex-column">
                              <span className="text-nowrap mb-3">
                                {property.bathroom_amount > 0
                                  ? property.bathroom_amount +
                                    " Baño" +
                                    (property.bathroom_amount > 1 ? "s" : "")
                                  : ""}
                              </span>
                              <span className="text-nowrap mb-3">
                                {property.toilet_amount > 0
                                  ? property.toilet_amount +
                                    " Toilet" +
                                    (property.toilet_amount > 1 ? "s" : "")
                                  : ""}
                              </span>
                            </div>
                          </div>
                        ):''}
                        {/* //Cocheras */}
                        {property.parking_lot_amount > 0 ? (
                          <div className="item-extra d-flex align-items-start px-3 mt-lg-3 mb-2">
                            {property.bathroom_amount > 0 && (
                              <div className="pt-lg-1">
                                <i className="icon-car2 me-4"></i>
                              </div>
                            )}
                            <div className="d-flex flex-column">
                              <span className="text-nowrap mb-3">
                                {property.parking_lot_amount > 0
                                  ? property.parking_lot_amount +
                                  " Cochera" +
                                  (property.parking_lot_amount > 1 ? "s" : "")
                                : ""}
                              </span>
                            </div>
                          </div>
                        ):''}
                      </div>
                      <div className="div">
                        <div className="d-flex mt-0 w-100">
                          {property.roofed_surface > 0 || property.surface > 0 || property.total_surface > 0 || property.semiroofed_surface > 0 || property.unroofed_surface > 0  ?(
                            <i className="icon-casa me-lg-3 me-2 pt-lg-1"></i>
                          ) : ''}
                          <div>
                            {property.total_surface > 0 && (
                            <div className="item-extra d-flex align-items-start mt-0 px-2 mb-2 w-100">
                              <div className="w-100">
                                <span>
                                  {property.total_surface > 0
                                    ? new Intl.NumberFormat("de-DE").format(
                                        parseInt(property.total_surface).toFixed(0)
                                      ) + " m2 total "
                                    : ""}
                                </span>
                              </div>
                            </div>
                            )}
                            {property.roofed_surface > 0 && (
                              <div className="item-extra d-flex align-items-start px-2 mt-lg-1 mt-0 mb-2 w-100">
                                <div className="w-100">
                                  <span>
                                    {property.roofed_surface > 0
                                      ? new Intl.NumberFormat("de-DE").format(
                                          parseInt(property.roofed_surface).toFixed(0)
                                        ) + " m2 cubiertos "
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            )}
                            {property.semiroofed_surface > 0 && (
                              <div className="item-extra d-flex align-items-start px-2 mt-lg-1 mt-0 mb-2 w-100">
                                <div className="w-100">
                                  <span>
                                    {property.semiroofed_surface > 0
                                      ? new Intl.NumberFormat("de-DE").format(
                                          parseInt(property.semiroofed_surface).toFixed(0)
                                        ) + " m2 semi-cubierta "
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            )}
                            {property.unroofed_surface > 0 && (
                              <div className="item-extra d-flex align-items-start px-2 mt-lg-1 mt-0 mb-2 w-100">
                                <div className="w-100">
                                  <span>
                                    {property.unroofed_surface > 0
                                      ? new Intl.NumberFormat("de-DE").format(
                                          parseInt(property.unroofed_surface).toFixed(0)
                                        ) + " m2 descubierta "
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            )}
                            {property.surface > 0 && (
                              <div className="item-extra d-flex align-items-start px-2 mt-lg-1 mt-0 mb-2 w-100">
                                <div className="w-100">
                                  <span>
                                    {property.surface > 0
                                      ? new Intl.NumberFormat("de-DE").format(
                                          parseInt(property.surface).toFixed(0)
                                        ) + " m2 terreno "
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="d-flex">
                          {/* //Orientación */}
                          {property?.orientation?.length > 0 ? (
                            <div className="item-extra d-flex align-items-start mt-lg-3 mb-2">
                              {property?.orientation?.length > 0 && (
                                <div className="pt-lg-1">
                                  <i className="icon-orentation me-lg-4 me-3"></i>
                                </div>
                              )}
                              <div className="d-flex flex-column">
                                <span className="text-nowrap mb-3">
                                    {property?.orientation}
                                </span>
                              </div>
                            </div>
                          ):''}
                        </div>
                      </div>
                    </div>

                </div>
              </div>
              <div className="col-12 d-none">
                {property.room_amount > 0 || property.suite_amount > 0 ? (
                  <div className="item-extra d-flex ">
                    <i className="icon-bed me-4"></i>
                    {property.room_amount > 0
                      ? property.room_amount +
                        " ambiente" +
                        (property.room_amount > 1 ? "s" : "")
                      : ""}
                    {property.suite_amount > 0
                      ? " / " +
                        property.suite_amount +
                        " dormitorio" +
                        (property.suite_amount > 1 ? "s" : "")
                      : " "}
                  </div>
                ) : (
                  ""
                )}
                {property.bathroom_amount > 0 ? (
                  <div className="item-extra d-flex ">
                    <i className="icon-bathtub me-4"></i>
                    {property.bathroom_amount > 0
                      ? " " +
                        property.bathroom_amount +
                        " baño" +
                        (property.bathroom_amount > 1 ? "s" : "")
                      : ""}
                  </div>
                ) : (
                  ""
                )}
                {property.parking_lot_amount > 0 ? (
                  <div className="item-extra d-flex ">
                    {<i className="icon-garage me-4"></i>}
                    {property.parking_lot_amount > 0
                      ? +property.parking_lot_amount +
                        " cochera" +
                        (property.parking_lot_amount > 1 ? "s" : "")
                      : ""}
                  </div>
                ) : (
                  ""
                )}
                {property.total_surface > 0 || property.roofed_surface > 0 ? (
                  <div className="item-extra d-flex">
                    <i className="icon-surface me-4"></i>
                    {property.total_surface > 0
                      ? new Intl.NumberFormat("de-DE").format(
                          parseInt(property.total_surface).toFixed(0)
                        ) + " m2 totales "
                      : ""}
                    {property.roofed_surface > 0
                      ? " / " +
                        new Intl.NumberFormat("de-DE").format(
                          parseInt(property.roofed_surface).toFixed(0)
                        ) +
                        " m2 cubiertos "
                      : ""}
                  </div>
                ) : (
                  ""
                )}
                {property.surface > 0 ? (
                  <div className="item-extra d-flex">
                    <i className="icon-surface-terreno me-4"></i>
                    {property.surface > 0
                      ? new Intl.NumberFormat("de-DE").format(
                          parseInt(property.surface)
                        ) + " m2 de terreno"
                      : ""}
                  </div>
                ) : (
                  ""
                )}
                {property.custom_tags.length > 0 ? (
                  <div
                    className={
                      "item-extra d-flex " +
                      (property.custom_tags.length > 0 ? "d-block" : "d-none")
                    }
                  >
                    <i className="icon-gym me-4"></i>
                    {property.custom_tags.map((item, index) => (
                      <>
                        {item.name}
                        {index > 0 ? " / " : ""}
                      </>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row px-5">
              <h3 className={"mt-5 h3-amenities" + (property.tags.length === 0 ? ' d-none ' : '')} >
                Amenities
              </h3>
              <div className={"px-3 separador-amenities" + (checkAtributes() ? ' ' : ' d-none ')}>
                <hr className="mt-2 mb-5 px-3" />
              </div>
              <div className="amenites-grid">
                {property.tags.map((item, index) => (
                  <div className="mb-3">
                    <div className="item d-flex">
                      <div className="tick d-flex justify-content-center align-items-center me-3">
                        <i className="icon-tick"></i>
                      </div>
                      {item.name}
                    </div>
                  </div>
                ))}
                <div className="w-100 border-1"></div>
              </div>
              <div className={"px-3 " + (property.custom_tags.length > 0 ? "d-block" : "d-none") }>
                <h3 className="mt-5 h3-amenities" >
                  Amenities especiales
                </h3>
                <div className="separador-amenities">
                  <hr className="mt-3 mb-4 px-3" />
                </div> 
              </div>
              <div className="amenites-grid mt-3">
                {property.custom_tags.map((item, index) => (
                <>
                  <div className="mb-3">
                    <div className="item d-flex">
                      <div className="tick d-flex justify-content-center align-items-center me-3">
                        <i className="icon-tick"></i>
                      </div>
                      {item.name}
                    </div>
                  </div>
                </>
                ))}
              </div>
            </div>
          </div>
          {/* //Tour */}
          <div
            className={
              "col-lg-12 " +
              (searchTour(property.videos) ? "d-block" : "d-none")
            }
          >
            <h2 className="title-1 dark-blue">Tour Virtual</h2>
            <iframe
              className="global_view"
              allowfullscreen=""
              src={searchTour(property.videos).player_url}
              frameborder="0"
            ></iframe>
          </div>
          {/* //Video */}
          <div
            className={
              "col-lg-12 " +
              (searchYoutube(property.videos) ? "d-block" : "d-none")
            }
          >
            <h2 className="title-1 mb-lg-4 dark-blue">Video</h2>
            <iframe
              className="global_view"
              allowfullscreen=""
              src={
                searchYoutube(property.videos)
                  ? searchYoutube(property.videos).player_url
                  : ""
              }
              frameborder="0"
            ></iframe>
            {}
          </div>
          {/* //Ubicación */}
          <div className="col-lg-12 mb-3 mt-5">
            <h2 className="title-1 mb-lg-5 dark-blue">Ubicación</h2>
          </div>
        </div>
        <div style={{boxShadow: "rgb(219 218 218) 0px 20px 60px"}}>
          <Map
            coordenadas={{ geo_lat: property.geo_lat, geo_long: property.geo_long }}
            />
        </div>
      </div>
      <Contact property={property} />
    </section>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};
export default Data;
