export const getFeatured =  (data) => {
  if(data)
    return data.objects.filter(element => element.is_starred_on_web === true)
  return []
}

export const shortLocation = (full_location,position) => {
    var split = full_location.split("|")
    return split[position]
}

export const shortFull_Location = (full_location) => {
    var split = full_location.split("|");
    return split[2] + "| " + split[1];
  }

export const getPricesUnityPrice = (unity_development) => {
    var valueUnity = [];
    for (const iterator of unity_development.objects) {
      valueUnity.push(iterator.operations[0].prices[0].price);
    }
    return [Math.min.apply(null, valueUnity), Math.max.apply(null, valueUnity)];
  }

export const get_short_desc = (description,max_caracters) => {
    if (description.length > max_caracters) {
      return description.slice(0, max_caracters) + '...';
    }
    return description;
};