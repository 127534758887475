import Filter from './filter'
import Properties from './result-properties'

const Grid = () =>{
    return(
        <div className="container-fluid ">
            <div className=" d-flex">
                <Filter></Filter>
                <Properties></Properties>
            </div>
        </div>
    )
}

export default Grid