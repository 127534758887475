import React from 'react'
import CircularText from '../assets/images/circulo-negocios-red.png'

export default function LogoRotation() {
    return (
        <div className="logo-rotation">
            <img className="text" src={CircularText} alt="negocios inmobiliarios" />
        </div>
    )
}
