import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { useSelector } from "react-redux";
import ImageBox from "../imageBox";
const ImageBoxContainer = () => {
  const { data } = useSelector((store) => store.developments.development);
  
  return data ? (
    <section
    >
      <ImageBox photos={data.photos}/>
    </section>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};

export default ImageBoxContainer;
