import React from "react";
import { Link } from "react-router-dom";
import FavoriteIcon from "../assets/images/icons/favorite1-icon.svg";
import LocationIcon from "../assets/images/icons/location-icon.svg";
import RightArrowIcon from "../assets/images/icons/right-arrow-icon.svg";
import BedIcon from "../assets/images/icons/bed-icon.svg";

export default function cardPropery({
  name,
  image,
  address,
  location,
  beds,
  cost,
  route = "/",
}) {
  return (
    <div className="card-property shadow-1">
      <img className="lotes-slider-img" src={image} alt={name} />
      {false && <button className="btn-rounded">Alquiler</button>}

      <div className="item-description">
        <div>
          {" "}
          <h4 className="d-flex gap-3 align-items-center">
            {name}
            {false && (
              <img
                src={FavoriteIcon}
                style={{ width: 20, height: 20 }}
                alt="favorito"
              />
            )}
          </h4>
          <div className="d-flex justify-content-start gap-2">
            <div className="description">
              <i className="icon-location-icon me-2"></i>
              <span>{address} </span>
            </div>
            <div className="description">
              {location && <span>| {location}</span>}
              {beds && (
                <>
                  <span>{beds}</span>
                  <img src={BedIcon} alt="ubicación" height="15px" />
                </>
              )}
            </div>
          </div>
          {cost && <span className="cost">{cost}</span>}
        </div>

        
      </div>
    </div>
  );
}
