
import { useEffect } from 'react'
import {getfavorites} from '../../functions/functions_property'
import {useSelector,useDispatch} from 'react-redux'
import { getFavoritesAction,clearFavoritesAction } from '../../redux/propertiesDucks'
import Properties from '../property-card.jsx'
import BreadCrumbs from "../breadcrumbs";

const Main = () =>{
    const dispatch = useDispatch();
    const settings = useSelector(store => store.settings)
    const favorites = useSelector(store => store.properties.favorites)
    const loading = useSelector(store => store.properties.loading)
    const theme = useSelector(store => store.theme)


    useEffect(() => {
        dispatch(clearFavoritesAction())
        for (const iterator of getfavorites("prop",settings.short_name)) {
            dispatch(getFavoritesAction(iterator))
        }
    },[])

    return  (
        <section className={"main" + (theme.dark ? ' dark' : '')}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <BreadCrumbs
                                props={[
                                    { name: "Home", route: settings.base_url, location: "" },
                                    {
                                    name: "Favoritos",
                                    route: "",
                                    location: "",
                                    active: true,
                                    },
                                ]}
                                />
                    </div>
                    <div className="col-12 container-fluid">
                        <h1 className="title-1 my-4 dark-blue">Favoritos</h1>
                        <div className="row py-lg-5">
                            {loading 
                            ? 
                                <div className="loading-section position-relative"><div className="lds-dual-ring"></div> </div>
                            :
                                favorites.length > 0 
                                ?
                                    favorites.map((item,index) =>(
                                        <div className="col-lg-4 mb-3">
                                            <Properties property={item.data} />
                                        </div>
                                    ))
                                :
                                    <div className="col-12 "><h4 className={theme.dark ? "text-white" : 'text-primary-color'}>No hay propiedades favoritas.</h4></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
} 

export default Main