// constantes
const dataInicial = {
    name:'La Compañia de Cariló',
    short_name:'carilo',
    base_url:'/',
    colegio:'CUCICBA',
    matricula_id:'4567',
    social:
    {
        instagram:'https://instagram.com/lacompaniadecarilo.ok',
        facebook:'https://www.facebook.com/lacompaniadecarilo.ok',
        twitter:'/',
        linkedin:'/',
        youtube:'/',
    },
    propertyContact: {
        mail: "",
        phone: ["4700-0025", "4722-5533"]
    },
    contact:
    {
        phone:['(02254) 579040/9050' , '(11) 4788-6865'],
        whatsapp:[
            {link:'+5492254419000',visual:'41-9000'},
            {link:'+5492254533000',visual:'53-3000'}
        ],
        mail:['info@lacompaniadecarilo.com.ar', 'contactenos@lacompaniadecarilo.com.ar'],
        
    },
    location:
    {
        address:'Ctro. Comercial Cariló, Local 11 - Av.Divisadero 1526',
        region:'Cariló',
        cp:'7167'
    },
    location2:
    {
        address:'Quesada 1610 Piso 1ro C',
        region:'Buenos Aires',
        cp:'1429'
    },
    keys:{
        // captcha:'6Lc9VaAaAAAAANaHwR9avf7MCpVP7z019S-g_OMi', Mediahaus
        // captcha:'6LfyMHIeAAAAAOFmFwJD5LLxwM3qdpjKbOWU6Jrw', carilo.com
        captcha:'6Lcc2ScfAAAAALj81K0IHM02UZ4Fg14d-ebKRW95', //carilo.com.ar
        tokko:'cb92806bf373af361cd5ee659d6a0054594ee9a0',
        google_tgm:'',
        google_map:'AIzaSyBqHrhWefZ2gUKNNNXgr59IavKhrqmqio4',
    },
    seo:{
        title:'La Compañia de Carilo',
        description:'',
        url:'',
        img:'',
        image_url:'',
        site_name:'Compania Carilo',
        locale:'es_ES',
        type:'website'
    },
    infoUtil: [
        {
            id: 0,
            name: "Balnearios Cariló",
            data: [
                {
                    id: 0,
                    title: "Parador Divisadero del Mar.",
                    loc: "Dirección: Acacia y playa",
                    tel: "Tel: (02254) 57-2010"
                },
                {
                    id: 1,
                    title: "Hemingway",
                    loc: "Lambertiana y Playa",
                    tel: "Tel: (02254) 57-0585",
                    web: "www.paradorhmngwy.com"
                },
                {
                    id: 2,
                    title: "Cozumel",
                    loc: "Dirección: Acacia y la Playa",
                    tel: "Tel: (02254) 47-0246",
                    web: "www.cozumelcarilo.com.ar"
                },
                {
                    id: 3,
                    title: "Parador Neruda",
                    loc: "Dirección: Aromo y Playa",
                    tel: "Tel: 02254 57-2136",
                    web: "www.paradorneruda.com"

                }
            ]
        },
        {
            id: 1,
            name: "Deportes",
            data: [
                {
                    id: 0,
                    title: "Tennis Ranch, Clases de tenis, torneos, recreación",
                    loc: "Dirección: Casuarina y Perdíz",
                    tel: "Tel: (02254) 57-1078"
                },
                {
                    id: 1,
                    title: "Cariló Golf Club",
                    loc: "Dirección: Ñandú entre Choique y Perdiz",
                    tel: "Tel: (02254) 47-0044"
                },
                {
                    id: 2,
                    title: "Aeroclub Pinamar",
                    loc: "Dirección: Km 8 - Ruta 74",
                    tel: "Tel: (02254) 49-3953"
                },
                {
                    id: 3,
                    title: "Polo de Alto Hándicap",
                    loc: "Dirección: En La Herradura, Pinamar",
                    web: "www.laherradurapinamar.com.ar"
                }
            ]
        },
        {
            id: 2,
            name: "Iglesias",
            data: [
                {
                    id: 0,
                    title: "Capilla Ntra. Sra. del Perpetuo Socorro",
                    loc: "Dirección: Carpintero y Algarrobo - Cariló",
                    tel: "Tel:  (02254) 49-1101/ 02"
                }
            ]
        },
        {
            id: 3,
            name: "Transportes",
            data: [
                {
                    id: 0,
                    title: "Terminal de Ómnibus",
                    loc: "Dirección: Av. Bunge e Intermédanos",
                    tel: "Tel:  (02254) 40-3500"
                },
                {
                    id: 1,
                    title: "Central de Taxis",
                    tel: "Tel:  (02254) 49-6435"
                },
                {
                    id: 2,
                    title: "Expreso Nigro",
                    tel: "Tel:  (011) 4743-0666 / (02254) 48-3116"
                },
            ]
        },
        
        {
            id: 4,
            name: "Empresas de Alarmas",
            data: [
                {
                    id: 0,
                    title: "IASSA - Central de Alarmas",
                    loc: "Direccion: Avda. Constitución 1060 e/Shaw y Eneas - Pinamar",
                    tel: "Tel:  (02254) 48-3490",
                    web: "www.iassa-sa.com.ar"                },
                {
                    id: 1,
                    title: "SANTAMARINA - Central de Alarmas",
                    loc: "Dirección: Av. Divisadero 1526. Local 4A",
                    tel: "Tel: (02254) 57-1806",
                    web: "www.santamarina-alarmas.com.ar"
                },
                {
                    id: 2,
                    title: "Central de Alarmas",
                    loc: "Dirección: Pinzon Nº798 esq. Espora Loc 3 y 4, Valeria del Mar",
                    tel: "Tel: 0810- 444 – 4522",
                    web: "www.sosalarmas.com.ar"
                }
            ]
        },  
        {
            id: 5,
            name: "Supermercados y Varios",
            data: [
                {
                    id: 0,
                    title: "Supermercado La Proveeduría",
                    loc: "Dirección: Centro Comercial Cariló",
                    tel: "Tel: (02254) 47-0049/ 470558"
                },
                {
                    id: 1,
                    title: "Cariló Market",
                    loc: "Dirección: Avellano y Calandria",
                    tel: "Tel: (02254) 57- 1241",
                },
                {
                    id: 2,
                    title: "Pescaderia – Di Costanzo",
                    tel: "Tel: (02254) 48-0291/ 579383"
                }
            ]
        }, 
        {
            id: 6,
            name: "Recreación y entretenimiento",
            data: [
                {
                    id: 0,
                    title: "Secretaria de Cultura de Pinamar",
                    loc: "Dirección: Avda.Constitución 687, Pinamar",
                    tel: "Tel: (02254) 49-1678"
                },
                {
                    id: 1,
                    title: "Teatro La Torre",
                    loc: "Dirección: Av. Constitución y Valle Fértil",
                    tel: "Tel: (02254) 49- 1678"
                },
                {
                    id: 2,
                    title: "Cine – Oasis",
                    loc: "Dirección: Av. Shaw y Lenguado",                 
                    tel: "Tel:  (02254) 48-3334"
                },
                {
                    id: 3,
                    title: "Alquiler de caballos - Dalmiro",
                    loc: "Dirección: Zorzal entre Urrutau y Urraca",                 
                    tel: "Tel: (02267) 15 63 6682"
                },
                {
                    id: 4,
                    title: "Alquiler de Caballos – Maito",
                    loc: "Dirección: Paraíso y Tero. Cariló",                 
                    tel: "Tel: (02267) 15-666363"
                },
                {
                    id: 5,
                    title: "Paintball – Sector 400",
                    loc: "Dirección: Ruta 11, Km 400. Acceso por estación de servicio Shell.",                 
                    tel: "Tel: 011- 15 - 3438-0083",
                    web: "www.sector400.com.ar"
                },
                {
                    id: 6,
                    title: "Pinamar Park",
                    loc: "Dirección: Ruta 11 Km 398,5 Pinamar",                 
                    tel: "Tel:  02254-15411346",
                    web: "www.pinamarpark.com.ar"
                },
                {
                    id: 7,
                    title: "PIEDRA LIBRE – Recreación Infantil",               
                    tel: "Tel: 011-15-6500-9217"
                },
                {
                    id: 8,
                    title: "ANIMAR – Recreación Infantil",              
                    tel: "Tel: 011 153 1452 0121"
                },
                {
                    id: 9,
                    title: "Alquiler de Cuatriciclos y Bicicletas – Nico Beach",   
                    loc: "Dirección: Espora y Esparza",            
                    tel: "Tel: (02254) 48-6914/ 487694"
                },
                {
                    id: 10,
                    title: "Alquiler de Cuatriciclos y Bicicletas MOTORRAD",   
                    loc: "Dirección: Plaza Cerezo entre Av. Divisadero y Boyero ",           
                    tel: "Tel:  (02254) 47- 0437"
                },
                {
                    id: 11,
                    title: "Alquiler de Cuatriciclos - MOTORRAD Playa ",  
                    loc: "Dirección: Acacia y playa",       
                    tel: "Tel: (02254) 47-0109"
                },
                {
                    id: 12,
                    title: "Laguna Los Horcones", 
                    loc: "Dirección: Ruta 74, Km 12",              
                    tel: "Tel:  (02267) 15-666326"
                },
                {
                    id: 13,
                    title: "Casino de Valeria del Mar",  
                    loc: "Dirección: Ruta Interbalnearia, acceso a Valeria del Mar",       
                    tel: "Tel: (02254) 48-6427"
                },
                {
                    id: 14,
                    title: "Bingo Pinamar",  
                    loc: "Dirección: Constitución 299",       
                    tel: "Tel: (02254) 40-3290"
                },
                {
                    id: 15,
                    title: "Casino Hotel del Bosque",  
                    loc: "Dirección: Júpiter esquina Bunge",       
                    tel: "Tel:  (02254) 40-6174"
                }
            ]
        },

        {
            id: 7,
            name: "Proveedores de servicios",
            data: [
                {
                    id: 0,
                    title: "CALP – Cooperativa de Agua y Luz",
                    loc: "Dirección: Av. Shaw 766, entre Melgacho y Trucha",
                    tel: "Tel: (02254) 48-2262"
                },
                {
                    id: 1,
                    title: "PROAGAS S.A",
                    loc: "Dirección: De las Náyades 1978 - Galería Náyades Plaza",
                    tel: "Tel: 02254 40-3900",
                    web: "www.proagas.com.ar"
                },
                {
                    id: 2,
                    title: "LCC La Capital Cable",
                    loc: "Dirección: Rivadavia 556",
                    tel: "Tel: 0810- 444 – 4522"
                },
                {
                    id: 3,
                    title: "TELPIN – Cooperativa Telefonica de Pinamar Ltda.",
                    tel: "Tel: (02254) 48-3000",
                    web: "www.telpin.com.ar"
                },
                {
                    id: 4,
                    title: "DirectTV",
                    tel: "Tel: (02254) 49-30392",
                    web: "www.directvpinamar.com"
                },
                {
                    id: 5,
                    title: "Carilo Digital",
                    loc: "Divisadero, B7169 Cariló, Provincia de Buenos Aires",
                    tel: "Tel: 02254-414034",
                    web: "www.carilodigital.com.ar"
                }
            ]
        },
              {
                    id: 8,
                    name: "Teléfonos de emergencia",
                    data: [
                        {
                            id: 0,
                            title: "Policia",
                            tel: "Tel: *911",
                        },
                        {
                            id: 1,
                            title: "Policia Federal",
                            tel: "Tel: 49-1320",
                  
                        },
                        {      
                            id: 2,
                            title: "Destacamento de Cariló",
                            tel: "Tel: 47-0537",
                  
                        },
                        {
                            id: 3,
                            title: " Destacamento de Valeria",
                            tel: "Tel: 49-1214",
                  
                        },
                        {
                            id: 4,
                            title: "Emergencia Playa",
                            tel: "Tel: *108",
                  
                        },
                        {
                            id: 5,
                            title: "Emergencia Marítima",
                            tel: "Tel: *106",
                  
                        },
                        {
                            id: 6,
                            title: "Bomberos Voluntarios de Pinamar",
                            tel: "Tel: 02254 49-1300",
                            web: "bomberosdepinamar@telpin.com.ar"
                        },
                        {
                            id: 7,
                            title: "Emergencias Pinamar",
                            tel: "Tel: (02254) 49-2442"
                        },
                        {
                            id: 8,
                            title: "Municipalidad Pinamar",
                            loc: "Dirección: Av. Shaw 18", 
                            tel: "Tel: 49-1666",
                            Web: "www.pinamar.gov.ar"
                        }
                    ]
                },
                    {
                    id: 9,
                    name: "Farmacias y Centros Médicos",
                    data: [
                        {
                            id: 0,
                            title: "Farmacia Castillo",
                            loc: "Dirección: Paseo de la Horqueta",
                            tel: "Tel: (02254) 57-0677",
                        },
                        {
                            id: 1,
                            title: "Farmacia Outón",
                            loc: "Dirección: Centro Comercial Cariló L. 17",
                            tel: "Tel: (02254) 47-0190/ 57-1022",
                        },
                        {
                            id: 2,
                            title: "Centro de Medico – Dr Julio Batalla",
                            loc: "Dirección: Cerezo 342 local 2 Paseo Cerezo",
                            tel: "Tel: 02254 - 49 9095 / 02254 15 530073"
                         },
                        {
                            id: 3,
                            title: "Pinamed Salud",
                            tel: "Tel: 48-0255 / 48-2223"
                         },
                        {
                            id: 4,
                            title: "Policlínica Bunge",
                            loc: "Dirección: Av. Enrique Shaw 59",
                            tel: "Tel: 02254 48-2400"
                        },
                        {
                            id: 5,
                            title: "Clínica del Bosque",
                            loc: "Dirección: Del Pejerrey 1305",
                            tel: "Tel: 02254 - 409490 / 407388",
                            web: "Web: www.clinicadelbosque.com.ar"
                         },
                        {
                            id: 6,
                            title: "Hospital Comunitario de Pinamar",
                            loc: "Dirección: Cerezo 342 local 2 Paseo Cerezo",
                            tel: "Tel: (02254) 49-1670"
                         },
                        {
                            id: 7,
                            title: "Centro de Médico – Dr Julio Batalla",
                            loc: "Dirección: Shaw 250 entre Medusas y Rivadavia",
                            tel: "Tel: 02254 - 49 9095 / 02254 15 530073"
             
                        }
                    ]
                },

                
               {
                    id: 10,
                    name: "Alquiler Ropa Blanca y Servicios varios",
                    data: [
                        {
                            id: 0,
                            title: "BIC-LAU, Servicio y Mucama y Ropa Blanca",
                            tel: "Tel: ( 02254 ) 15 41-5999"
                        },
                        {
                            id: 1,
                            title: "TENE-BOLETA, Tintorería y alquiler de ropa blanca",
                            loc: "Dirección: Del Lenguado 1360, Pinamar",
                            tel: "Tel: (02254) 49-8182"
                        },
                        {
                            id: 2,
                            title: "SOL DE ESPAÑA, Lavadero y tintorería",
                            loc: "Dirección: España 890 - Ostende",
                            tel: "Tel: (02254) 49-8715"
                        },
                        {
                            id: 3,
                            title: "CAFFI III, Tintoreria y alquiler de ropa blanca",
                            loc: "Dirección: Av. del Libertador y de las Artes - Pinamar",
                            tel: "Tel: (02254) 48-4019",
                            web: "www.cafi3.com.ar/"
                        },
                        {
                            id: 4,
                            title: "El Trébol, Venta de leña y gas envasado",
                            loc: "Dirección: Calle Leandro Rosales",
                            tel: "Tel: 02254 48 6211"
                        },
                        {
                            id: 5,
                            title: "GUARDERIA DE VEHICULOS, Candado Náutico",
                            loc: "Dirección: Andes 418 - Valeria del Mar",
                            tel: "Tel: 02254-15-50-9881"
                        },
                        {
                          id: 6,
                            title: "GUARDERIA DE VEHICULOS, Candado Náutico",
                            loc: "Dirección: Andes 418 - Valeria del Mar",
                            tel: "Tel: 02254-15-50-9881"    
                        },
                        {
                            id: 7,
                            title: "GUARDERIA DE VEHICULOS S.O.S",
                            loc: "Dirección: Pinzon Nº798 esq. Espora Loc 3 y 4, Valeria del Mar",
                            tel: "Tel: (02254) 57-0524 / 57-0754"
                        }
                    ]
                },
                {
                    id: 11,
                    name: "Restaurantes y Bares",
                    data: [
                            {
                                id: 0,
                                title: "Camelia Sensi, la clásica casa de té y restaurante",
                                loc: "Dirección: Boyero e/ Av. Cerezo y Avellano - Paseo Camelia Sensi",
                                tel: "Tel: 011 15-6820-1361 /(02254) 470369"
                            },
                            {
                                id: 1,
                                title: " GRILLER Meet & Wine",
                                loc: "Dirección: Castaño y Benteveo - Paseo las Golondrinas",
                                tel: "Tel: 02254 57-1935",
                                web: "www.grillernyc.com"
                            },
                            {
                                id: 2,
                                title: "De Mi Campo Restaurant",
                                loc: "Dirección: Avellano 181",
                                tel: "Tel: (02254) 57-2862"
                            },
                            {
                                id: 3,
                                title: " Tiramisú",
                                loc: "Dirección: Avutarda 980",
                                tel: "Tel: 02254 57-0705",
                                web: "www.hosteriacarilo.com.ar/tiramisu.html"
                            },
                            {
                                id: 4,
                                title: "Bar de Tapas",
                                loc: " Dirección: Boyero 1498",
                                tel: "Tel: 02254 57-0710",
                            },
                            {
                                id: 5,
                                title: "Josefa – La Pasta de Carilo",
                                loc: "Dirección: Boyero 1498 esquina Castaño",
                                tel: "Tel: 02254 57-9497",
                            },
                            {
                                id: 6,
                                title: " Hemingway Restaurante",
                                loc: "Dirección: Lambertiana y el mar",
                                tel: "Tel: (02254) 57-1585",
                            },
                            {
                                id: 7,
                                title: "A Mano",
                                loc: "Dirección: Avellano esquina Benteveo",
                                tel: "Tel: (02254) 57-1411"
                            },
                            {
                                id: 8,
                                title: "Almacén de Pizzas",
                                loc: "Dirección: Castaño 244 - Paseo Felicitas",
                                tel: "Tel: (02254) 571540",
                            },
                            {
                                id: 9,
                                title: "Tante",
                                loc: "Dirección: Av. Divisadero entre Cerezo y Avellano",
                                tel: "Tel: (02254) 57- 1111",
                            },
                            {
                                id: 10,
                                title: "Pizzería Salvatore",
                                loc: "Dirección: Castaño 1498 Feria del Bosque",
                                tel: "Tel: (02254) 57- 1416",
                            },
                            {
                                id: 11,
                                title: " La Pulpería – Parrilla Asador",
                                loc: "Dirección: Divisadero entre Avellano y Cerezo",
                                tel: "Tel: Tel: (02254) 570296",
                                web: "www.hosteriacarilo.com.ar/tiramisu.html"
                            },
                            {
                                id: 12,
                                title: " Green Café Bar",
                                loc: "Dirección: Centro Comercial Cariló",
                                tel: "Tel: Tel: (02254) 57-1571"
                            }
                        ]
        
                  }
      
    ]
}
export default function settingsReducer(state = dataInicial, action){
    return state
}