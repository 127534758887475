import React , {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import { getDevelopmentsAction } from '../redux/developmentsDucks';
import { getPropertiesAction,clearPropertyAction } from '../redux/propertiesDucks';

// -- Componentes -- //
import Main from '../components/inicio/main'
import Emprendimientos from '../components/inicio/lotes'
import Tasaciones from '../components/inicio/tasaciones'
import About from '../components/inicio/about'

function Inicio(){

    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);
    const locationStatus = useLocation();

    useEffect(() => {
        const defaultSettings = () => {
            dispatch(getDevelopmentsAction())
            dispatch(getPropertiesAction())
            document.title = settings.seo.title
            window.scrollTo(0, 0);
        }
        defaultSettings()
    }, [])

    useEffect(() => {
        dispatch(clearPropertyAction());
    } , [locationStatus])

    return(
        <div className="Inicio"> 
            <Main />
            <Emprendimientos />
            <Tasaciones /> 
            <About />
        </div>
    )
}

export default Inicio
