import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { changeLocationAction } from "../../redux/themeDucks";
import {
  getAlquileresFeaturedAction,
  getVentasFeaturedAction,
  getFavoritesAction,
  clearFavoritesAction,
  getFullPropertiesAction,
} from "../../redux/propertiesDucks";

import {
  getFrontCover,
  getLocationProperty,
  getPrice,
  shortDirection,
  addFavorites,
  getfavorites,
} from "../../functions/functions_property";

import OwlCarousel from "react-owl-carousel2";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import BedIcon from "../../assets/images/icons/lightb-bed-icon.svg";
import LocationIcon from "../../assets/images/icons/lightb-location-icon.svg";
import FavoriteIcon from "../../assets/images/icons/favorite2-icon.svg";
import PlusIcon from "../../assets/images/icons/plus.svg";

const options = {
  items: 1,
  nav: false,
  rewind: true,
  dots: false,
  // autoplay: true,
  loop: true,
  center: true,
  startPosition: 1,
  stageOuterClass: "py-lg-5 py-2 position-relative",
  navClass: ["slider-left-button", "slider-right-button"],
  navText: "",
  // stageOuterClass: "h-100",
  // stageClass: "h-100",
  // dragClass: "h-100",
  // stageClass:"test"
  responsive: {
    1024: {
      items: 2,
      nav: true,
      dots: true,
    },
  },
};

function Lotes() {
  const dispatch = useDispatch();
  const slider = useRef();
  const [typeLote, setTypeLote] = useState("alquiler temporario");
  const [currentSlider, setCurrentSlider] = useState(1);

  const settings = useSelector((store) => store.settings);
  const venta = useSelector(
    (store) => store.properties.properties_featured_venta
  ).data;
  const alquiler = useSelector(
    (store) => store.properties.properties_featured_alquiler
  ).data;
  const { data } = useSelector((store) => store.properties.properties);
  const [loading, setLoading] = useState(false);

  const [listFavorites, setListFavorites] = useState(
    getfavorites("prop", settings.short_name)
  );

  useEffect(() => {
    dispatch(getVentasFeaturedAction());
    dispatch(getAlquileresFeaturedAction());
  }, []);

  const getForType = (type) => {
    let properties = [];
    for (const iterator of data.objects) {
      iterator.operations.map((item) => {
        if (item.operation_type.toLowerCase() === type.toLowerCase()) {
          properties.push(iterator);
        }
      });
    }
    return properties;
  };

  const checkActive = (id) => {
    setListFavorites(getfavorites("prop", settings.short_name));
    dispatch(clearFavoritesAction());
    for (const iterator of getfavorites("prop", settings.short_name)) {
      //Por cada uno de los favoritos, traigo su respectiva data de la api
      dispatch(getFavoritesAction(iterator));
    }
  };

  useEffect(() => {
    if (data) {
      if (getForType("alquiler temporario").length === 0) {
        setTypeLote("alquiler");
      }
      if (!getForType("alquiler").length === 0) {
        setTypeLote("venta");
      }
    }
  }, [data]);

  return data ? (
    <div className="lotes">
      <div className="">
        <div className="container-fluid">
          <h2 className="title-1 dark-blue">
            Conocé nuestras propiedades destacadas
          </h2>
        </div>

        <div className="double-btn">
          <button
            className={
              " " +
              (typeLote === "alquiler temporario" ? "btn-active" : "") +
              (getForType("alquiler temporario").length === 0 ? " d-none" : "")
            }
            disabled={getForType("alquiler temporario").length === 0}
            onClick={() => {
              setTypeLote("alquiler temporario");
            }}
          >
            Alquiler temporario
          </button>
          <div></div>
          <button
            className={
              " " +
              (typeLote === "alquiler" ? "btn-active" : "") +
              (getForType("alquiler").length === 0 ? " d-none" : "")
            }
            disabled={getForType("alquiler").length === 0}
            onClick={() => {
              setTypeLote("alquiler");
            }}
          >
            Alquiler
          </button>
          <div></div>
          <button
            className={" " + (typeLote === "venta" ? "btn-active" : "")}
            disabled={getForType("venta").length === 0}
            onClick={() => {
              setTypeLote("venta");
            }}
          >
            Venta
          </button>
        </div>
        <div className="lotes-slider mt-5">
          {!loading && getForType(typeLote).length > 0 ? (
            <OwlCarousel options={options} className="owl-themes d-flex">
              {getForType(typeLote).map((item) => (
                <div key={item.id} className={"lotes-slider-item"}>
                  <img
                    className="lotes-slider-img"
                    src={item.photos[0].original}
                    alt=""
                  />
                  <div className="operation-buttons">
                    {item.operations.map((operation) => (
                      <a className="mb-3 me-2">
                        <button
                          className={
                            "me-2 btn-rounded " + operation.operation_type
                          }
                          style={{
                            borderRadius: "10px",
                            padding: ".50rem 1.5rem",
                            fontSize: "16px",
                          }}
                        >
                          {" "}
                          {operation.operation_type}
                        </button>
                      </a>
                    ))}
                  </div>
                  <div className="item-description">
                    <h4 className="d-flex gap-3 align-items-center">
                      {item.fake_address}{" "}
                    </h4>
                    <div className="d-flex justify-content-start gap-2">
                      <div className="description">
                        <i className="icon-location1"></i>
                        <span className="lb">
                          {getLocationProperty(item.location.full_location)}. |
                        </span>
                      </div>
                    </div>
                    <div className="d-lg-flex justify-content-lg-between align-items-center cost-icon-wrap">
                      <div className="d-block">
                        {item.web_price === false ? (
                          <span className="cost align-self-center w-100">
                            Precio a consultar
                          </span>
                        ) : (
                          item.operations.map((operation) => (
                            <span className="cost align-self-center w-100">
                              {getPrice(operation.prices[0])} <br />
                            </span>
                          ))
                        )}
                      </div>
                      <span className="space d-lg-flex d-block">
                        <div
                          onClick={() =>
                            addFavorites(item.id, "prop", settings.short_name) +
                            checkActive()
                          }
                          className={
                            "d-flex justify-content-center align-items-center me-3 favorite-wrap " +
                            (listFavorites.find(
                              (element) =>
                                element.toString() === item.id.toString()
                            )
                              ? "active "
                              : "")
                          }
                        >
                          <img
                            src={FavoriteIcon}
                            style={{ width: 32, height: 32 }}
                            alt="favorito"
                          />
                        </div>
                        <Link
                          className="arrow-link"
                          onClick={() =>
                            dispatch(changeLocationAction("propiedad"))
                          }
                          to={
                            settings.base_url +
                            "propiedad/" +
                            shortDirection(
                              item.operations[0].operation_type,
                              item.type.name,
                              item.location.name,
                              item.id,
                              item.fake_address
                            )
                          }
                        >
                          <img
                            src={PlusIcon}
                            alt="flecha apunta a la derecha"
                          />
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          ) : (
            ""
          )}
          <Link
            to={settings.base_url + "ventas"}
            className="btn-medium d-inline-flex"
          >
            Ver más propiedades
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
}

export default Lotes;
