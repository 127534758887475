import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import {formTokko,updateFields} from "../../redux/contactDucks";
import ContactCard from "./contactCard";


export default function Contact() {
  const dispatch = useDispatch();
  const settings = useSelector((store) => store.settings);
  const [text, setText] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [datos, setDatos] = useState({
    nombre: "",
    apellido: "",
    email: "",
    text: "",
  });
  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    const update = () => {
      dispatch(updateFields(datos));
    };
    update();
  }, [datos]);
  const sendForm = (event) => {
    event.preventDefault();
    dispatch(formTokko("Contacto"));
    document.getElementById("formContact").reset();
    setText("¡Mensaje enviado correctamente!");
  };
  return (
    <div className="container-fluid mt-lg-5 div-contactanos">
      <div className="row align-items-start">
        <div className="col-lg-12 contact-main pt-lg-5 px-lg-3 px-2">
          <div className="row text-start text-md-start">
            <div className="col-lg-6">
              <h2 className="title-1  mb-4">Contactate <br /> con nosotros</h2>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-wrap flex-md-nowrap justify-content-center">
                <ContactCard data={{
                  contact: settings.contact, 
                  location: settings.location, 
                  location2: settings.location2
                }}/>
              </div>
            </div>
          </div>
          <form id="formContact" onSubmit={sendForm}>
            <div className="row">
              <div className="col-lg-6 d-flex flex-column align-items-start order-1 order-lg-1">
                <div className="input-wrapper mb-3">
                  <label>Nombre*</label>
                  <input
                    type="text"
                    name="nombre"
                    placeholder="......"
                    required
                    id=""
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-wrapper mb-3">
                  <label>Apellido*</label>
                  <input
                    type="text"
                    required
                    name="apellido"
                    placeholder="......"
                    id=""
                    onChange={handleInputChange}
                  />
                </div>

                <div className="w-100">
                  <div className="input-wrapper">
                    <label>Mail*</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="......@mail.com.ar"
                      id=""
                      required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="d-none d-lg-block">
                    <span
                      id="text-form"
                      className={(text === "" ? "d-none" : "d-block") + " mt-5"}
                    >
                      {text}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-3  order-lg-2">
                <div className="input-wrapper h-100 mt-3 mt-lg-0">
                  <label>Mensaje*</label>
                  <textarea
                    name="text"
                    placeholder="Escribe tu mensaje aquí..."
                    id=""
                    cols="670"
                    required
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <p className="mt-0 mb-0 mb-lg-4 text-dark d-block text-start w-100 mt-2 dark-blue ps-3">
                  *Campos obligatorios
                </p>
                <div className="d-block d-lg-none">
                  <span
                    id="text-form"
                    className={(text === "" ? "d-none" : "d-block") + " my-lg-4 mb-3 mt-3"}>
                    {text}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6 order-lg-4 order-4 mt-5 pt-5 mt-lg-4">
                <div className="d-lg-flex flex-column text-center justify-content-center justify-content-md-start align-items-center align-items-md-start">
                  {settings ? (
                    <div className="px-0 mb-4">
                      <ReCAPTCHA
                        sitekey={settings.keys.captcha}
                        onChange={() => setDisabled(false)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    type="submit"
                    className="btn-rounded transparent mt-lg-0"
                    disabled={disabled}
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
