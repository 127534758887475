import React , {useEffect} from 'react'
import {useParams } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import { getDevelopmentAction } from '../redux/developmentsDucks';
import { getUnitsDevelopmentAction } from '../redux/developmentsDucks';

import Data from '../components/emprendimiento/data'
import Lotes from '../components/emprendimiento/lotes'
import Contact from '../components/Contact';

function Emprendimiento(){
    const dispatch = useDispatch();
    const {id} = useParams(); 
    useEffect(() => {
        const getDev = () => {
            dispatch(getDevelopmentAction(id))
            dispatch(getUnitsDevelopmentAction(id))
            window.scrollTo(0, 0);}
        getDev()
    }, [])
        

    return(
        <div className="Emprendimiento">
            <Data />
            <Lotes />
            <Contact name="Emprendimiento" />
        </div>
    )
}

export default Emprendimiento
