import { useSelector } from "react-redux";
import BreadCrumbs from "../breadcrumbs";
import MapImg from "../../assets/images/map.jpg";
import CariloMap from "../../assets/images/carilo-map.svg";
import Compass from "../../assets/images/compass.svg";

import Map from "../map";

const Main = () => {
    const settings = useSelector((store) => store.settings);

    return (
        <div className="mapa">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <BreadCrumbs
                            props={[
                                { name: "Home", route: settings.base_url, location: "" },
                                {
                                    name: "Mapa",
                                    route: "",
                                    location: "",
                                    active: true,
                                },
                            ]}
                            />
                    </div>
                </div>
                <div className="row flex-column my-5" >
                    <h1 className="title-1 dark-blue mb-5 d-block">Mapa de Cariló</h1>
                    {/* <p className="paragraph-1 dark-blue">Lorem ipsum dolor sit amet consectetur adipiscing elit, dignissim faucibus in lacus fames facilisis.</p> */}
                    <div className="" style={{boxShadow:"0px 20px 60px #DBDADA"}}>
                        <Map coordenadas={{ geo_lat:-37.160998, geo_long: -56.9030086 }}/>
                    </div>
                </div>
                <div className="row flex-column map-2">
                    <h1 className="title-1 dark-blue mb-5 d-block">Zonas de Cariló</h1>
                    {/* <p className="paragraph-1 dark-blue">Lorem ipsum dolor sit amet consectetur adipiscing elit, dignissim faucibus in lacus fames facilisis.</p> */}
                    <div className="image2 position-relative">
                        <div style={{ backgroundImage: `url(${CariloMap})` }}></div>
                        <img src={Compass} alt="Brújula" className="compass" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main