import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import BreadCrumbs from "../breadcrumbs";
import { updateFields, formTasaciones_Tokko } from "../../redux/contactDucks";
import Search from "../autoComplete";

const Main = () => {
  const dispatch = useDispatch();

  const type = useRef();
  const operation = useRef();

  const settings = useSelector((store) => store.settings);
  const { data } = useSelector((store) => store.properties.listFilters);
  const [step, setStep] = useState(0);
  const [locations, setLocation] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [text, setText] = useState("");

  const [datos, setDatos] = useState({
    nombre: "",
    email: "",
    ubicacion: "",
    sup_cub: "",
    sup_tot: "",
    rooms: "",
    garage: "",
    age: "",
    amenities: [],
  });

  useEffect(() => {
    console.log(datos);
  }, [datos]);
  const handleInputChange = (event) => {
    if (event.target.name == "amenities") {
      if (
        datos.amenities.find(
          (element) =>
            element.toLowerCase() === event.target.value.toLowerCase()
        )
      ) {
        const newAmenities = datos.amenities.filter(
          (element) =>
            element.toLowerCase() !== event.target.value.toLowerCase()
        );
        setDatos({
          ...datos,
          [event.target.name]: [newAmenities],
        });
        return null;
      }
      setDatos({
        ...datos,
        [event.target.name]: [...datos.amenities, event.target.value],
      });
      return null;
    }
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };
  const sendForm = (event) => {
    event.preventDefault();
    dispatch(formTasaciones_Tokko(type.current.value, operation.current.value));
    setStep(2);
    window.scrollTo(0, 0);
    setText("¡Mensaje enviado correctamente!");
    document.getElementById("formContact").reset();
  };
  useEffect(() => {
    const update = () => {
      dispatch(updateFields(datos));
    };
    update();
  }, [datos]);

  useEffect(() => {
    const getDevs = () => {
      if (step == 2) {
        $(".results-nav").removeClass("results-nav").addClass("contact-nav");
      }
    };
    getDevs();
  });


  return (
    <section className="main">
      <div className="container-fluid">
        <div className="">
          <div className="row">
            <div className="d-flex align-items-start flex-column col-lg-12 ">

              <div className="d-flex flex-column justify-content-center">
                <BreadCrumbs
                  props={[
                    { name: "Home", route: settings.base_url, location: "" },
                    {
                      name: "Tasaciones",
                      route: "",
                      location: "",
                      active: true,
                    },
                  ]}
                />
              </div>
            </div>

            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-lg-6 text-center text-md-start">
                  <h2 className="title-1 ">
                    ¿Necesitas saber<br />cuál es el&nbsp;valor<br />de tu&nbsp;propiedad?
                  </h2>
                  <p className="title-description ">
                    Llena el formulario, completalo con todos los datos y nosotros te ayudamos.
                  </p>
                  {/* <div className="mancha-after6"></div> */}
                </div>

                <div
                  className="col-lg-6 formulario d-flex flex-column justify-content-center position-relative"
                  style={{ zIndex: 100 }}
                >
                  <form
                    onSubmit={sendForm}
                    id="formContact"
                    className=" mt-2 content-form "
                  >
                    <div className="row search-results m-0">
                      <div className="input-wrapper flex-column align-items-start mb-4">
                        <label className="input-label">
                          Nombre y Apellido*
                        </label>
                        <input
                          type="text"
                          name="nombre"
                          required
                          onChange={handleInputChange}
                          placeholder="......."
                        />
                      </div>
                      <div className="input-wrapper shadow-3 mb-4">
                        <label className="input-label">Email*</label>
                        <input
                          type="text"
                          name="email"
                          required
                          onChange={handleInputChange}
                          placeholder=".......@mail.com.ar*"
                        />
                      </div>

                      <div className="input-wrapper shadow-3 mb-4">
                        <label className="input-label">Ubicación</label>
                        {/* <Search lang={locations} updateRoute={() => {}} /> */}
                        <input
                          type="text"
                          placeholder="......."
                          required
                          onChange={handleInputChange}
                          name="ubicacion"
                          id="ubicacion"
                        />
                      </div>

                      <div className="filter-types d-lg-flex mb-4 position-relative">
                        <div className="d-flex gap-3 pe-0 pe-lg-3">
                          <span className="icon-dollar-arrow mt-1 "></span>
                          <div className="w-100">
                            <span className="filter-selected">Tipo de operación</span>
                            <select
                              className="w-100"
                              required
                              ref={operation}
                              name="operation"
                            >
                              {/* <option value="">Tipo de operación</option> */}
                              <option value="Venta">Venta</option>
                              <option value="Alquiler">Alquiler</option>
                              <option value="Alquiler Temporario">
                                Alquiler Temporario
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="d-flex gap-3 pe-0 pe-lg-3 position-relative">
                          <span className="icon-house mt-1 "></span>
                          <div className="w-100">
                            <span className="filter-selected">
                              Tipo de propiedad
                            </span>
                            <select
                              className="w-100"
                              ref={type}
                              required
                              name="type"
                            >
                              
                              {data ?
                                data.objects.property_types
                                .sort((a, b) => b.count - a.count)
                                .map((item, index) => (
                                  <option value={item.type}>{item.type}</option>
                                ))
                              :''
                              }
                            </select>

                          </div>
                        </div>
                      </div>

                      <div className="col-12 align-items-center justify-content-between mt-5 mb-5 div-cantidad-ambientes">
                        <label className="input-label ">
                          {" "}
                          Ambientes
                        </label>
                        <div className="mt-4 mt-lg-3 d-flex gap-2 justify-content-between justify-content-md-between align-items-center flex-wrap w-100 pt-2">
                          <span className="option">
                            <input
                              type="radio"
                              onClick={handleInputChange}
                              id="amb-1"
                              name="rooms"
                              value="1"
                            />
                            <label for="amb-1" className="mb-0">
                              <span className="">1</span>
                            </label>
                          </span>
                          <span className="option">
                            <input
                              type="radio"
                              onClick={handleInputChange}
                              id="amb-2"
                              name="rooms"
                              value="2"
                            />
                            <label for="amb-2" className="mb-0">
                              <span className="">2</span>
                            </label>
                          </span>
                          <span className="option">
                            <input
                              type="radio"
                              id="amb-3"
                              onClick={handleInputChange}
                              name="rooms"
                              value="3"
                            />
                            <label for="amb-3" className="mb-0">
                              <span className="">3</span>
                            </label>
                          </span>
                          <span className="option">
                            <input
                              type="radio"
                              id="amb-4"
                              onClick={handleInputChange}
                              name="rooms"
                              value="4"
                            />
                            <label for="amb-4" className="mb-0">
                              <span className="">4</span>
                            </label>
                          </span>
                          <span className="option">
                            <input
                              type="radio"
                              id="amb-5"
                              onClick={handleInputChange}
                              name="rooms"
                              value="5"
                            />
                            <label for="amb-5" className="mb-0">
                              <span className="">5</span>
                            </label>
                          </span>
                          <span className="option">
                            <input
                              type="radio"
                              id="amb-6"
                              onClick={handleInputChange}
                              name="rooms"
                              value="6"
                            />
                            <label for="amb-6" className="mb-0">
                              <span className="">+</span>
                            </label>
                          </span>
                        </div>
                      </div>

                      <div className="filter-types mt-4 mb-5 shadow-3 d-lg-flex">
                        <div className="surface1 d-block">
                          <label className="input-label">
                            Superficie cubierta
                          </label>
                          <input
                            type="text"
                            name="sup_cub"
                            onChange={handleInputChange}
                            placeholder="xxx m2"
                          />
                        </div>
                        <div className="surface2 d-block">
                          <label className="input-label">
                            Superficie total
                          </label>
                          <input
                            type="text"
                            onChange={handleInputChange}
                            name="sup_tot"
                            placeholder="xxx m2"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 div-antiguedad div-cantidad-ambientes">
                        <label className="input-label  mb-3">
                          Antigüedad
                        </label>
                        <div className=" ticks d-flex gap-2 justify-content-start gap-4 align-items-center flex-wrap w-100 pt-2">
                          
                          <div>
                            <span className="option d-flex align-items-center ">
                              <input
                                type="radio"
                                id="age-1"
                                name="age"
                                onClick={handleInputChange}
                                value="A estrenar"
                              />
                              <label for="age-1">
                                <span>A estrenar</span>
                              </label>
                            </span>
                          </div>
                          <div>
                            <span className="option">
                              <input
                                type="radio"
                                id="age-2"
                                name="age"
                                onClick={handleInputChange}
                                value="Hasta 10 años"
                              />
                              <label for="age-2">
                                <span>Hasta 10 años</span>
                              </label>
                            </span>
                          </div>
                          <div className="">
                            <span className="option ">
                              <input
                                type="radio"
                                id="age-3"
                                name="age"
                                onClick={handleInputChange}
                                value="10 - 50 años"
                              />
                              <label for="age-3">
                                <span>10 - 50 años</span>
                              </label>
                            </span>
                          </div>
                          <div className="">
                            <span className="option">
                              <input
                                type="radio"
                                id="age-4"
                                onClick={handleInputChange}
                                name="age"
                                value="+50 años"
                              />
                              <label for="age-4">
                                <span>+50 años</span>
                              </label>
                            </span>
                          </div>
                       
                        </div>
                        
                      </div>
                      {/* <div className="col-lg-6 camp d-none d-lg-block mt-5">
                        <label className="input-label">Garage</label>
                        <div className="ticks d-flex justify-content-start align-items-center">
                          <span className="option  mr-5">
                            <input
                              type="radio"
                              id="garage-1"
                              onClick={handleInputChange}
                              name="garage"
                              value="Si"
                            />
                            <label for="garage-1" >
                              <span >Sí</span>
                            </label>
                          </span>
                          <span className="option  mr-5">
                            <input
                              type="radio"
                              id="garage-2"
                              onClick={handleInputChange}
                              name="garage"
                              value="No"
                            />
                            <label for="garage-2">
                              <span >No</span>
                            </label>
                          </span>
                        </div>
                      </div> */}
                      <div className="col-12 camp  mt-5 mt-lg-5 div-antiguedad div-cantidad-ambientes">
                        <label className="input-label  mb-3">
                          Otras características
                        </label>
                        <div className="ticks d-flex align-items-center justify-content-start gap-4 flex-wrap">
                          <div className="">
                            <span className="option ">
                              <input
                                type="checkbox"
                                id="Pileta"
                                name="amenities"
                                onClick={handleInputChange}
                                value="Pileta"
                              />
                              <label for="Pileta">
                                <span>Pileta</span>
                              </label>
                            </span>
                          </div>
                          <div className="">
                            <span className="option ">
                              <input
                                type="checkbox"
                                id="Apto crédito"
                                name="amenities"
                                onClick={handleInputChange}
                                value="Apto crédito"
                              />
                              <label for="Apto crédito">
                                <span>Apto crédito</span>
                              </label>
                            </span>
                          </div>
                          <div className="">
                            <span className="option ">
                              <input
                                type="checkbox"
                                id="Parrilla"
                                name="amenities"
                                onClick={handleInputChange}
                                value="Parrilla"
                              />
                              <label for="Parrilla">
                                <span>Parrilla</span>
                              </label>
                            </span>
                          </div>
                          <div className="">
                            <span className="option ">
                              <input
                                type="checkbox"
                                id="Solarium"
                                name="amenities"
                                onClick={handleInputChange}
                                value="Solarium"
                              />
                              <label for="Solarium">
                                <span>Solarium</span>
                              </label>
                            </span>
                          </div>
                          <div className="">
                            <span className="option ">
                              <input
                                type="checkbox"
                                id="Apto profesional"
                                name="amenities"
                                onClick={handleInputChange}
                                value="Apto profesional"
                              />
                              <label for="Apto profesional">
                                <span>Apto profesional</span>
                              </label>
                            </span>
                          </div>
                          <div className="">
                            <span className="option ">
                              <input
                                type="checkbox"
                                id="Gimnasio"
                                name="amenities"
                                onClick={handleInputChange}
                                value="Gimnasio"
                              />
                              <label for="Gimnasio">
                                <span>Gimnasio</span>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="subrayado">
                        <p>*Campos obligatorios.</p>
                      </div>
                      <div className=" mt-5 text-center text-lg-start">
                        <div className="d-flex flex-column-reverse justify-content-center justify-content-sm-start">
                          <div
                            className="ms-0 ms-sm-0 "
                            style={{ overflow: "hidden" }}
                          >
                            <ReCAPTCHA
                              sitekey={settings.keys.captcha}
                              onChange={() => setDisabled(false)}
                            />
                          </div>
                          {/* <p className="ms-0 ms-md-3 dark-blue">*Campos obligatorios.</p> */}
                        </div>

                          <div className="d-block pt-5 pt-lg-0">
                            <span
                              id="text-form"
                              className={(text === "" ? "d-none" : "d-block") + " mt-0 pt-lg-0 mb-1"}
                            >
                              {text}
                            </span>
                          </div>
                        <button
                          id="btn-state"
                          disabled={disabled}
                          className="btn-rounded btn-send mt-4 py-3 px-5"
                        >
                          Enviar
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
