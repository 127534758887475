import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLocationAction } from "../redux/themeDucks";
import { updateFields, formTokko } from "../redux/contactDucks";
import ReCAPTCHA from "react-google-recaptcha";
import { getWhatsapp } from "../functions/functions_contact";
import Logo from '../assets/images/logo-header.svg'
import FacebookIcon from "../assets/images/icons/facebook2-icon.svg";
import InstagramIcon from "../assets/images/icons/instagram2-icon.svg";
import TwitterIcon from "../assets/images/icons/twitter2-icon.svg";
import KeyIcon from "../assets/images/icons/key-icon.svg";
import MessageIcon from "../assets/images/icons/message-icon.svg";
import { useHistory } from "react-router-dom";
import Header from "./header.jsx";
import imgWpp from "../assets/images/img-wpp.png"

function Footer() {
  const dispatch = useDispatch();
  const router = useHistory();
  const [offset, setOffset] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [text, setText] = useState("");
  const [coordenadas, setCoordenadas] = useState(5000);
  const settings = useSelector((store) => store.settings);
  const theme = useSelector((store) => store.theme);
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
      setCoordenadas($("footer").offset().top);
      // console.log(window.pageYOffset, coordenadas)
    };
  }, [setOffset]);

  function scrollTop() {
    $("html, body").scrollTop(0);
    return true;
  }

  const [datos, setDatos] = useState({
    email: "",
  });
  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };
  const sendForm = (event) => {
    event.preventDefault();
    dispatch(formTokko('Newsletter'))
    document.getElementById("formContact").reset();
    setText("¡Newsletter enviado correctamente!");
  };
  useEffect(() => {
    const update = () => {
      dispatch(updateFields(datos));
    };
    update();
  }, [datos]);

  function isChangeBG(word) {
    return router.location.pathname.includes(word);
  }

  const getYear = () => {
    return new Date().getFullYear();
  }

  // Float WP btn
  const [open,setOpen] = useState(false)

  return (
    <div className={"footer-home"}>
      <footer className="text-center text-lg-start">
        <div
          className={
            "fixed-bar position-fixed " +
            (offset <= coordenadas - 900 ? "" : "opacity")
          }
        >
          {/* Pulse animation */}
          <div className="pulse-border"></div>
          <>
            {/* Desktop WP Api */}
            <a href={"https://web.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[0].link} target="_blank" className={"branch-btn contact-btn d-none d-lg-flex " + (open ? 'open' : ' ')}>Alquileres</a>
            <a href={"https://web.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[1].link} target="_blank" className={"branch-btn contact-btn d-none d-lg-flex " + (open ? 'open' : ' ')}>Ventas</a>
            {/* Mobile WP Api */}
            <a href={"https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[0].link} target="_blank" className={"branch-btn contact-btn d-flex d-lg-none " + (open ? 'open' : ' ')}>Alquileres</a>
            <a href={"https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[1].link} target="_blank" className={"branch-btn contact-btn d-flex d-lg-none " + (open ? 'open' : ' ')}>Ventas</a>
          </>
          {/* WP btn */}
          <a onClick={() => setOpen(!open)} className="icon-whatsapp contact-btn d-flex bg-green"></a>
        </div>
        <div className="container-fluid">
            <Header/>
          <div className="row">
            <div className=" col-lg-3 col-md-12  dark-blue div-bloque-uno">
              <div className="d-flex text-center text-lg-start footer-login-button matricula">
                <div>
                  <span>Matrícula</span>
                </div>

                <div className="hover-text">
                  <span className="text-center">Javier Cedres Battro <br /> T° ||| F° 378</span>
                </div>
              </div>
              <div className="primer-bloque-info">
                <span style={{ fontWeight: 500 }}>
                  {settings.location.region}:
                </span>
                <br className="d-none d-lg-block" />
                <br className="d-none d-lg-block" />
                < div className="d-lg-flex align-items-start">
                  <i className="icon-location1 ms-lg-1"></i>
                  <p>{settings.location.address},
                     CP: {settings.location.cp}
                  </p>
                </div>
                < div className="d-lg-flex align-items-start">
                  <i className="icon-celular1"></i>
                  <p>
                    <a href={settings.contact.phone[0]} className="footer-link" >
                      {settings.contact.phone[0]}
                    </a>
                  </p>  
              </div>
              < div className="d-lg-flex align-items-start">
                <i className="icon-whatsapp"></i>
                <p className="d-flex justify-content-center">
                  (+54 2254)
                  <a target="_blank" className="ms-1" href={"https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[0].link}>{settings.contact.whatsapp[0].visual}</a>
                  <div className="mx-1">/</div>
                  <a target="_blank" href={"https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[1].link}>{" " + settings.contact.whatsapp[1].visual}</a>
                </p>
              </div>
              < div className="d-lg-flex align-items-start">
                <i className="icon-ico-sendmail"></i>
                <p>
                  <a className="footer-link"
                    href={"mailto:" + settings.contact.mail[0]} >
                    {settings.contact.mail[0]}
                  </a>
                </p>
              </div>

                  
              </div> 
              
                
            </div>
            <div className=" col-lg-3 col-md-12 dark-blue div-bloque-dos">
              <div className="d-lg-flex d-none justify-content-center footer-social-media ">
                <div className="content-icon"><a target="_blank" className="icon-facebook1" href={settings.social.facebook}></a></div>
                <div className="content-icon"><a target="_blank" className="icon-instagram1" href={settings.social.instagram}> </a></div>
              </div>
              <div className="segundo-bloque-info">
                <span style={{ fontWeight: 500 }}>
                  {settings.location2.region}:
                </span>
                <br className="d-none d-lg-block" />
                <br className="d-none d-lg-block" />
                < div className="d-lg-flex align-items-start">
                  <i className="icon-location1 ms-lg-1"></i> 
                  <p>{settings.location2.address}, CP: {settings.location2.cp}</p>
                </div>
                < div className="d-lg-flex align-items-start">
                  <i className="icon-celular1"></i>
                  <p>
                    <a href={settings.contact.phone[1]} className="footer-link" >
                      {settings.contact.phone[1]}
                    </a>
                  </p>
                </div> 
                < div className="d-lg-flex align-items-start">
                  <i className="icon-whatsapp"></i>
                  <p className="d-flex justify-content-center">
                    (+54 2254)
                    <a target="_blank" className="ms-1" href={"https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[0].link}>{settings.contact.whatsapp[0].visual}</a>
                    <div className="mx-1">/</div>
                    <a target="_blank" href={"https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[1].link}>{" " + settings.contact.whatsapp[1].visual}</a>
                  </p>
                </div> 
                < div className="d-lg-flex align-items-start">
                  <i className="icon-ico-sendmail"></i>
                  <p> 
                    <a
                      className="footer-link"
                      href={"mailto:" + settings.contact.mail[1]} >
                      {settings.contact.mail[1]}
                    </a>
                  </p>
                </div> 
              </div> 
              <div className="d-lg-none d-flex justify-content-center footer-social-media ">
                <div className="content-icon"><a target="_blank" className="icon-facebook1" href={settings.social.facebook}></a></div>
                <div className="content-icon"><a target="_blank" className="icon-instagram1" href={settings.social.instagram}> </a></div>
              </div>
            </div>

            {/* CUCICBA 7948 */}

            <div className="col-lg-6 col-md-12 d-flex flex-column div-bloque-tres">
              <div className="content-newsletter mb-lg-5">
                <form id="formContact" onSubmit={sendForm}
                  className="d-flex flex-column justify-content-center">
                  <label className="d-flex align-items-center gap-2 dark-blue">
                    {/* <img src={MessageIcon} alt="suscribite" /> */}
                    <div className="icon-ico-sendmail me-2"></div>
                    <span className="subscribe-text"> Suscribite a nuestro Newsletter</span>
                  </label>
                  <input
                    className="d-block"
                    type="text"
                    name="email"
                    required
                    onChange={handleInputChange}
                    placeholder="Tu email"
                  />
                  <button
                  className="btn-subscribe"
                  disabled={disabled}
                  >
                    Suscribirme
                  </button>
                </form>
              </div>
              <span
                id="text-form"
                className={
                  (text === "" ? "d-none" : "d-block") + " mt-2 mb-4"
                }
              >
                {text}
              </span>
              <div className="footer-captcha ms-0 d-flex w-100 justify-content-center justify-content-lg-end mt-5 ">
                <ReCAPTCHA
                  sitekey={settings.keys.captcha}
                  onChange={() => setDisabled(false)}
                />
              </div>
            </div>
            <div className="col-12 copy mt-auto d-flex  justify-content-lg-end pt-4">
              <p className=" m-0"><span className="text-break">Plataforma <a target="_blank" className="no-link" href="https://mediacore.com.ar/app"> MediaCore® </a> by <a className="mx-1" target="_blank" href="https://mediahaus.com.ar/">MediaHaus®</a></span> {getYear()}. <br className="d-block d-lg-none" />Todos&nbsp;los derechos&nbsp;reservados.</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
