import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import MetaTags from "react-meta-tags";
import ImageBox from '../imageBox'

const Main = () => {
  const [idProperty, setIdProperty] = useState(
    window.location.pathname.split("/")
  );
  const [location, setLocation] = useState(window.location.pathname.split("/"));
  const dispatch = useDispatch();
  const settings = useSelector((store) => store.settings);
  const property = useSelector((store) => store.properties.property).data;

  const getFrontCover = (photos) => {
    if (photos.length == 0) {
      return null;
    }
    if (photos.find((element) => element.is_front_cover == true)) {
      return photos.find((element) => element.is_front_cover == true);
    } else {
      return photos[0];
    }
  };

  const options = {
    settings: {
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },
    buttons: {
      iconColor: "#ffff",
    },
    caption: {
      captionColor: "#fff",
      captionFontFamily: "Raleway, sans-serif",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
    },
    progressBar: {
      height: "20px",
      fillColor: "#191919",
      backgroundColor: "white",
    },
  };

  useEffect(() => {
    setIdProperty(window.location.pathname.split("/")[6]);
  }, []);

  function getThreeImages() {
    const images = [];
    for (let i = 1; images.length < 3 && i < property.photos.length; i++) {
      if (!property.photos[i].is_front_cover) {
        images.push(property.photos[i]);
      }
    }

    return images.map((el, i) => (
      <img
        src={el.original}
        alt={el.description || "imagen de la propiedad " + i}
      />
    ));
  }

  return property ? (
    // <section className="main"  style={{'backgroundImage':"url(" + (getFrontCover(property.photos) ? getFrontCover(property.photos).original : 'https://i0.wp.com/elfutbolito.mx/wp-content/uploads/2019/04/image-not-found.png?ssl=1') +")"}}>
    <section className="imageboxs">
      <MetaTags>
        <title>
          {property.fake_address}, {property.type.name} en{" "}
          {property.operations[0].operation_type} - {settings.seo.title}
        </title>
        <meta name="description" content={settings.seo.description} />
      </MetaTags>
     
      <ImageBox photos={property.photos} />
    </section>
  ) : (
    ""
  );
};

export default Main;
