import { useDispatch, useSelector } from "react-redux";
import { changeLocationAction } from "../redux/themeDucks";
import { Link } from "react-router-dom";

const Breadcrumbs = (props) => {
  const dispatch = useDispatch();
  return (
    <div className="breadcrumb-wrapper mb-4">
      <ol className="breadcrumb">
        {props.props.map((item, index) => (
          <li className={"breadcrumb-item"}>
            {" "}
            {item.active ? (
              item.name
            ) : (
              <Link
                onClick={() => dispatch(changeLocationAction(item.location))}
                to={item.route}
              >
                {item.name}
              </Link>
            )}{" "}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Breadcrumbs;
