import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams,useHistory } from "react-router"
import { getPropertyAction } from "../redux/propertiesDucks"
import {shortDirection} from '../functions/functions_property'
import { Link } from "react-router-dom"

const Redirect = () =>{
    const {id} = useParams()
    const history = useHistory()
    const property = useSelector(store => store.properties.property).data
    const loading = useSelector(store => store.properties.loading)
    const error = useSelector(store => store.properties.error)
    const settings = useSelector(store => store.settings)
    const dispatch = useDispatch()
    useEffect(() => {
            dispatch(getPropertyAction(id))
    }, [])
    return property ? (
        <div>
            {history.push((settings.base_url) + "propiedad/" + shortDirection(property.operations[0].operation_type , property.type.name, property.location.name , property.id , property.fake_address.replaceAll("/","-")))}        
        </div>
    ):
    <>
        {(error && !loading) ?
            <div style={{minHeight:"100vh",backgroundColor:"#f3edec"}} className="d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <h1 className="title-2">No hay resultados que coincidan con la busqueda realizada.</h1>
                    <Link to={settings.base_url} className="btn-rounded mt-lg-5 mt-4 d-inline-block mx-auto">Volver al inicio.</Link>
                </div>
            </div>
        :
            (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
        }
    </>
}

export default Redirect