import React from 'react';
import { getWhatsapp } from "../../functions/functions_contact";
import { useSelector, useDispatch } from "react-redux";


function ContactCard({data}) {
    
    const settings = useSelector((store) => store.settings);
    const { contact, location, location2 } = data;

    const parsedData = [
        {
            id: 1,
            region: location.region,
            di: location.address,
            cp: location.cp,
            te: contact.phone[0],
            wa: contact.whatsapp,
            ma: contact.mail[0],
        },
        {
            id: 2,
            region: location2.region,
            di: location2.address,
            cp: location2.cp,
            te: contact.phone[1],
            wa: contact.whatsapp,
            ma: contact.mail[1],
        },
    ];

    const builtCard = (contactData) => contactData.map(c => 
        <div className="col-12 col-lg-6 dark-blue d-none d-lg-flex" key={c.id}>
            <div className="content d-flex flex-column py-4 px-3 mb-3 h-100 pe-xxl-5">
                <span className='pb-3'>{c.region}:</span>
                <span className='d-flex'><i className="icon-location1 d-block ms-lg-1 pe-3"></i> {c.di},{" CP: " + c.cp}</span>
                <span>
                <i className="icon-celular1 pe-3"></i>
                    <a className="dark-blue"
                            href={`tel:${c.te}`}>{c.te}</a>
                </span>
                <span className='d-flex align-items-center'>
                <i className="icon-whatsapp pe-3"></i>
                <div className="d-flex">
                    (+54 2254)
                    <a target="_blank" className="ms-1 dark-blue" href={"https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[0].link}>{settings.contact.whatsapp[0].visual}</a>
                    <div className="mx-1">/</div>
                    <a className="dark-blue" target="_blank" href={"https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[1].link}>{" " + settings.contact.whatsapp[1].visual}</a>
                </div>
                </span>
                <span>
                <i className="icon-ico-sendmail pe-3"></i> <a className="dark-blue wb"
                            href={`mailto:${c.ma}`}>{c.ma}</a>
                </span>
            </div>
        </div>
    );

    return (
        <div className="contact-card w-100">
            <div className="row gap-3 gap-lg-0">
                { builtCard(parsedData) }
            </div>
        </div>
    )
}

export default ContactCard
