import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changeLocationAction } from "../../redux/themeDucks";

function Tasaciones() {
  const dispatch = useDispatch();
  const settings = useSelector((store) => store.settings);

  return (
    <div className="tasaciones ">
      <div className="container-fluid position-relative">
        <div className="row justify-content-center justify-content-lg-between">
          <div className="col-lg-12 pe-xxl-0">
            <div className="row d-flex justify-content-center align-items-stretch text-center text-lg-start">
              <div className="col-12 col-lg-7  title-wrapper ">
                <h2 className="title-1 dark-blue">
                  Ahora podés tasar <br/> tu propiedad de&nbsp;forma rápida y&nbsp;segura.
                
                </h2>
              </div>

              <div className="col-12 col-lg-4 d-flex right-wrap">
                <div className="d-flex flex-column justify-content-between position-relative w-100 right-content">
                  <h3
                    className="title-2 dark-blue"
                    style={{ fontWeight: 400, maxWidth: "400px" }}
                  >
                    Completá los datos y conocé el valor de tu propiedad.
                  </h3>
                  <Link
                    to={settings.base_url + "tasaciones"}
                    onClick={() => dispatch(changeLocationAction("tasaciones"))}
                  >
                    <button
                      className="btn-rounded"
                      
                    >
                      Tasá tu propiedad ahora
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tasaciones;
