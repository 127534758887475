import {useSelector} from 'react-redux'
import { Link } from "react-router-dom";
import BreadCrumbs from '../breadcrumbs'
import {shortLocation} from '../../functions/functions_dev'
import CardPropery from '../cardPropery';

const Lotes = () =>{
    const {data} = useSelector(store => store.developments.developments)
    const settings = useSelector(store => store.settings)

    return data ? (
        <section className="container-fluid ">
            <div className="lotes">
                    <BreadCrumbs props={[
                        {name:'Home',route:settings.base_url,location:''},
                        {name:'Emprendimientos',route:'',location:'emprendimientos',active:true},
                    ]}/>
                {/* <span>NUESTROS EMPRENDIMIENTOS</span> */}
                <h2 className="title-1">
                    Conocé nuestras opciones <br className="d-lg-inline-block d-none" />
                    para tu próxima inversión.
                </h2>
                
                <div className="row">
                    {
                        data.length > 0 ?
                            data.objects.map((lote,index) =>  (
                                <div key={index} className="col-lg-6 mb-4">
                                    <CardPropery 
                                        name={lote.name}
                                        image={(lote.photos).find(element => element.is_front_cover === true).original}
                                        address={lote.fake_address}
                                        location={shortLocation(lote.location.full_location)}
                                        route={settings.base_url + 'emprendimiento/' + lote.id}
                                    />
                                </div>
                            ))
                            : 
                            <>
                                <h2 className="text-center py-3" style={{color:"rgb(107 107 107)"}}>No se encontraron emprendimientos.</h2>
                            </>
                    }
                </div>
            </div>
        </section>
    )   
    : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )

}

export default Lotes