import { useEffect } from "react"

import Main from '../components/info/main'


const Info = () =>{
    return  (
        <>
            <Main />
        </>
    )
}

export default Info