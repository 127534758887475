import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const ImageBox = ({ photos }) => {
  const options = {
    settings: {
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },
    buttons: {
      iconColor: "#ffff",
    },
    caption: {
      captionColor: "#fff",
      captionFontFamily: "Raleway, sans-serif",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
    },
    progressBar: {
      height: "20px",
      fillColor: "#191919",
      backgroundColor: "white",
    },
  };

  function getThreeImages() {
    const images = [];
    for (let i = 1; images.length < 3 && i < photos.length; i++) {
      if (!photos[i].is_front_cover) {
        images.push(photos[i]);
      }
    }

    return images.map((el, i) => (
      <img
        src={el.original}
        alt={el.description || "imagen de la propiedad " + i}
      />
    ));
  }
  const getFrontCover = (photos) => {
    if (photos.length == 0) {
      return null;
    }
    if (photos.find((element) => element.is_front_cover == true)) {
      return photos.find((element) => element.is_front_cover == true);
    } else {
      return photos[0];
    }
  };

  return (
    <div className="imagebox">
      <div className="grid-property-images">
        <div>
          <img
            width="100%"
            src={photos[0].original}
            src={
              getFrontCover(photos)
                ? getFrontCover(photos).original
                : "https://i0.wp.com/elfutbolito.mx/wp-content/uploads/2019/04/image-not-found.png?ssl=1"
            }
            alt=""
          />
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              <a className="open-images">
                <i className="icon-picture1"></i>
                <span className="count">Mostrar más fotos</span>
                <img
                  src={
                    getFrontCover(photos)
                      ? getFrontCover(photos).original
                      : "https://i0.wp.com/elfutbolito.mx/wp-content/uploads/2019/04/image-not-found.png?ssl=1"
                  }
                  className="position-absolute opacity-none"
                  style={
                    ({ width: "100%" }, { height: "100%" }, { opacity: "0" })
                  }
                  alt=""
                />
              </a>

              {photos.map((item, index) =>
                index > 0 ? (
                  <img className="d-none" src={item.original} alt="" />
                ) : (
                  ""
                )
              )}
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
        <div className="right-side">{getThreeImages()}</div>
      </div>
    </div>
  );
};

export default ImageBox;
