import React , {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getListFilters } from '../redux/propertiesDucks';

// -- Componentes -- //
import Main from '../components/tasaciones/main'

function Tasaciones(){

    const settings = useSelector((store) => store.settings);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getListFilters())
        window.scrollTo(0, 0);
        document.title = settings.seo.title
      }, []);
    return(
        <div className="tasaciones">
            <Main />
        </div>
    )
}

export default Tasaciones
