import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import BreadCrumbs from "../components/breadcrumbs"

// -- Componentes -- //
import Contact from "../components/contacto/main";

import Image from "../assets/images/contacto.jpg";
function Contacto() {
  const settings = useSelector((store) => store.settings);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = settings.seo.title;
  }, []);

  return (
    <div className="contacto">
      <div className="main container-fluid shadow-1 mb-3">
        <div className="image-wrapper ">
          <div style={{backgroundImage:`url(${Image})`}}></div>
        </div>
      </div>
      <div className="mt-5"></div>
      <div className="container-fluid">
        <BreadCrumbs
          props={[
            { name: "Home", route: settings.base_url, location: "" },
            {
              name: "Contacto",
              route: "",
              location: "",
              active: true,
            },
          ]}
        />
      </div>

        <Contact />

    </div>
  );
}

export default Contacto;
