import BreadCrumbs from "../breadcrumbs";
import { useState } from "react";
import { useSelector } from "react-redux";
import Map from "../map";
import {
  shortFull_Location,
  getPricesUnityPrice,
  get_short_desc,
} from "../../functions/functions_dev";
import { getWhatsapp } from "../../functions/functions_contact";
import BoxPhotos from "./boxphotos";
import Contact from "../Contact";

const Data = () => {
  // Store redux
  const settings = useSelector((store) => store.settings);
  const theme = useSelector((store) => store.theme);
  const unity_development = useSelector(
    (store) => store.developments.units_development.data
  );
  const { data } = useSelector((store) => store.developments.development);

  // States
  const [hidden, setHidden] = useState(true);
  const [max_caracters, setMax_caracter] = useState(700);

  // Render
  return data && unity_development ? (
    <section className={"data " + (theme.dark ? "dark" : "")}>
      <div className="container-fluid">
        <div className="row justify-content-between align-items-start">
          <div className="col-12">
            <BreadCrumbs
              props={[
                { name: "Home", route: settings.base_url, location: "" },
                {
                  name: "Emprendimientos",
                  route: settings.base_url + "emprendimientos",
                  location: "emprendimientos",
                },
                {
                  name: data.fake_address,
                  route: "",
                  location: "",
                  active: true,
                },
              ]}
            />
          </div>
          <div className="col-lg-6">
            <h1 className="title-1 text-center text-md-start">
              {data.fake_address ? data.fake_address : "Sin especificar"} <br />
              <div className="location mt-3">
                <i className="icon-location-icon me-1"></i>
                {shortFull_Location(data.location.full_location)}
              </div>
            </h1>
          </div>
          <div className="col-lg-5 d-flex align-items-start justify-content-center justify-content-lg-end overflow-hidden mt-0  mt-md-3">
            <div className="d-flex align-items-center overflow-hidden mb-1 mb-lg-0">
              <div
                className={
                  "d-flex align-items-center me-2 social-block " +
                  (hidden ? "hidden" : "")
                }
              >
                <a
                  href={
                    "mailto:" +
                    "?subject=" +
                    data.fake_address +
                    "&body=" +
                    window.location
                  }
                  style={{ padding: "10px" }}
                  className="btn-medium-dark d-flex align-items-center justify-content-center"
                >
                  <i className="icon-ico-sendmail"></i>
                </a>
                <a
                  href={getWhatsapp(
                    "",
                    "Hola, quería compartirte este emprendimiento de López Bisoglio y Oyarbide " +
                      window.location
                  )}
                  target="_blank"
                  style={{ fontSize: 20, padding: "10px 10px" }}
                  className="btn-medium-dark  ms-2 d-flex align-items-center justify-content-center"
                >
                  <i className="icon-whatsapp"></i>
                </a>
              </div>
              <button
                onClick={() => setHidden(!hidden)}
                style={{ fontSize: 20, padding: "10px 15px" }}
                className="btn-medium-dark me-2 d-flex align-items-center justify-content-center icon-share"
              ></button>
            </div>
            <button
              onClick={() => {}}
              className={
                "btn-medium-dark small d-flex justify-content-center align-items-center "
              }
              style={{ fontSize: 20, padding: "10px" }}
            >
              <i className="icon-solid-like"></i>
            </button>
          </div>
        </div>

        <BoxPhotos />

        <div className="row justify-content-between align-items-start mt-0 mt-md-5">
          <div className="col-lg-8">
            <h2 className="title-1">Descripción del emprendimiento</h2>
            <div className="col-12 my-3 my-md-5 ">
              <div className="type d-inline mb-0">{data.type.name}</div>
            </div>
            <p>
              {get_short_desc(data.description, max_caracters)}
              <a
                className="cursor-pointer see_description"
                onClick={() =>
                  max_caracters == 700
                    ? setMax_caracter(9999)
                    : setMax_caracter(700)
                }
              >
                {data.description.length > 700
                  ? max_caracters == 700
                    ? "Ver más"
                    : "Ver menos"
                  : ""}
              </a>
            </p>
          </div>
          <div className="property-data col-lg-4">
            {unity_development.objects.length > 0 ? (
              <div className=" mb-lg-0 property-atributes mt-0">
                <div
                  className="d-flex flex-wrap justify-content-between justify-content-md-around align-items-center"
                  style={{ gap: "1.5rem 0" }}
                >
                  <div className="item-extra d-flex justify-content-start mb-2 ">
                    <i
                      className="icon-building me-3"
                      style={{  fontSize: "1.5rem"  }}
                    ></i>
                    <div>
                      <h5
                        className="d-flex align-items-center"
                        style={{  fontSize: "1.1rem" }}
                      >
                        Tipo
                      </h5>
                      <span style={{ fontSize: 17, fontWeight: 400 }}>
                        {data.type.name}
                      </span>
                    </div>
                  </div>
                  <div className="item-extra d-flex justify-content-start mb-2">
                    <i
                      className="icon-dollar me-3 mt-1"
                      style={{ fontSize: "1.1rem" }}
                    ></i>
                    <div>
                      <h5
                        className="d-flex align-items-center"
                        style={{  fontSize: "1.1rem" }}
                      >
                        Desde
                      </h5>
                      <span style={{ fontSize: 17, fontWeight: 400 }}>
                        {unity_development.objects.length > 0
                          ? unity_development.objects[0].operations[0].prices[0]
                              .currency
                          : ""}{" "}
                        {new Intl.NumberFormat("de-DE").format(
                          getPricesUnityPrice(unity_development)[0]
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="item-extra d-flex justify-content-start mb-2 mb-md-0">
                    <i className="icon-weird-square me-3 mt-1" style={{  fontSize: "1.1rem" }}></i>
                    <div>
                      <h5
                      className="d-flex align-items-center"
                        style={{  fontSize: "1.1rem" }}
                      >
                        Unidades
                      </h5>
                      <span style={{ fontSize: 17, fontWeight: 400 }}>
                        {unity_development.meta.total_count}
                      </span>
                    </div>
                  </div>

                  <div
                    className="item-extra d-flex justify-content-start"
                    // style={{ paddingLeft: "41px" }}
                  >
                    <i className="icon-weird-square me-3 mt-1" style={{  fontSize: "1.1rem",color:"transparent" }}></i>
                    <div>
                      <h5
                        className="d-flex align-items-center"
                        style={{  fontSize: "1.1rem" }}
                      >
                        Hasta
                      </h5>
                      <span style={{ fontSize: 17, fontWeight: 400 }}>
                        {unity_development.objects.length > 0
                          ? unity_development.objects[0].operations[0].prices[0]
                              .currency
                          : ""}{" "}
                        {new Intl.NumberFormat("de-DE").format(
                          getPricesUnityPrice(unity_development)[1]
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <h3 className="my-5" style={{ fontSize: 30 }}>
                Amenities
              </h3>
              <div className="amenites-grid">
                {data.tags.map((item) => (
                  <div className=" mb-3">
                    <div className="item d-flex">
                      <div className="tick d-flex justify-content-center align-items-center me-3">
                        <i className="icon-tick"></i>
                      </div>
                      {item.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="px-3">
              <hr className="my-4 px-3" />
            </div>

            <div className="amenites-grid mt-3">
              <div className="mb-3">
                <div className="item d-flex">
                  <div className="tick d-flex justify-content-center align-items-center me-3">
                    <i className="icon-tick"></i>
                  </div>
                  Amenitie Esp 1
                </div>
              </div>
              <div className="mb-3">
                <div className="item d-flex">
                  <div className="tick d-flex justify-content-center align-items-center me-3">
                    <i className="icon-tick"></i>
                  </div>
                  Amenitie Esp 2
                </div>
              </div>
              <div className="mb-3">
                <div className="item d-flex">
                  <div className="tick d-flex justify-content-center align-items-center me-3">
                    <i className="icon-tick"></i>
                  </div>
                  Amenitie Esp 3
                </div>
              </div>
              <div className="mb-3">
                <div className="item d-flex">
                  <div className="tick d-flex justify-content-center align-items-center me-3">
                    <i className="icon-tick"></i>
                  </div>
                  Amenitie Esp 4
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="container-fluid location-section pb-5 mancha-after4">
        <h2 className="title-1 mb-1">Ubicación</h2>
      </div>
      <div style={{ zIndex: 100, position: "relative" }}>
        <Map coordenadas={{ geo_lat: data.geo_lat, geo_long: data.geo_long }} />
      </div>
    </section>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};

export default Data;
