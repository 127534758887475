import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLocationAction } from "../redux/themeDucks";
import { getListFilters,clearPropertiesAction,clearPropertyAction} from "../redux/propertiesDucks";
import LogoHeader from '../assets/images/logo-header1.svg'
import { useHistory } from "react-router-dom";

function Header() {
  const dispatch = useDispatch();
  const curLocation = useLocation()
  const settings = useSelector((store) => store.settings);
  const theme = useSelector((store) => store.theme);
  const [open, setOpen] = useState(false); 
  const router = useHistory();

  useEffect(() => {
    const checkLocation = () => {
      dispatch(getListFilters()); 
    };
    checkLocation();
    window.scrollTo(0, 0);
    dispatch(changeLocationAction(curLocation.pathname.split("/")[1]));
  },[curLocation])

  const ValidatorViews = (type) => {
      var className = ''
      switch(type){
        case 'header' : {
          theme.location == "loteo" ? className = "" :
          theme.location == "" ? className = "home-nav" : 
          theme.location == "ventas" || theme.location == "alquileres" ? className =  "results-nav":
          theme.location == "emprendimientos" ||theme.location == "emprendimiento" || theme.location == "propiedad" ? className =  "" :
          theme.location == "contacto" || theme.location == "favoritos" || theme.location == "tasaciones" ? className =  "white-cut-nav" : className = ""
          break;
        }
        case 'logo' : {
          theme.location == "propiedad" || window.location.pathname.split("/")[5] ? className = ( <img src={"" + "/assets/images/logo-header-white.svg"} alt="" className="logo d-none d-lg-block"/> ) : 
          (theme.location == 'alquileres' || theme.location == 'ventas') && theme.dark == true ? className = (<img src={"" + "/assets/images/logo-header-dark.svg"} alt="" className="logo d-none d-lg-block"/>) :
          theme.location == "" || window.location.pathname == "/" || theme.location == "ventas" || theme.location == "alquileres" || theme.location == "emprendimiento" || theme.location == "emprendimientos" ? className =  ( <img src={"" + "/assets/images/logo-header-white.svg"} alt="" className="logo d-none d-lg-block"/>) :
          className = (<img src={"" + "/assets/images/logo-header1-dark.svg"} alt="" className="logo d-none d-lg-block"/>)
          break;
        }
      }
      return className
  }

  const contactHeaderStyles = router.location.pathname.includes("contacto") ? "contact-header" : "";
  const homeValidation = router.location.pathname.split("/")[1] === '' ? 'home-nav' : '';

  return (
    <header className={"d-flex align-items-center " + homeValidation + contactHeaderStyles }>
      <div className="container-fluid d-flex py-3">
        <nav className="d-flex justify-content-between align-items-lg-starts align-items-center">
          <Link onClick={() => dispatch(changeLocationAction(""))} to={settings.base_url + ""} style={{zIndex:"999"}}>
            <img src={LogoHeader} alt="La Compañía de Cariló - Propiedades" />
            {/* {ValidatorViews('logo')} */}
          </Link>
          <div className="d-none mobile-nav">
            <div className="icons fav-icon me-3">
              <Link
                to={settings.base_url + "favoritos"}
                onClick={() =>
                  dispatch(
                    changeLocationAction("favoritos"),
                    setOpen(false),
                    $("html").removeClass("overflow-hidden-mobile")
                  )
                }
                className=""
              >
                <i
                  className={
                    "icon-solid-like " +
                    (theme.location == "favoritos" ? "" : "notActived")
                  }
                ></i>
              </Link>
            </div>
            <button onClick={() => setOpen(!open) + $("html").toggleClass("overflow-hidden-mobile")} className={"menu-toggle d-none " + (open ? "open" : "")} >
              <i></i>
              <i></i>
              <i></i>
            </button>
          </div>
          <ul className={"list-wrap " + (open ? "open" : "")}>
            <li className={curLocation.pathname ==settings.base_url + "" ? "active" : "hover-ball"}>
              <Link className="hover-ball" onClick={() =>dispatch(changeLocationAction(""),setOpen(!open),$("html").toggleClass("overflow-hidden-mobile")) + dispatch(clearPropertyAction())} to={settings.base_url + ""}>
                Home
              </Link>
            </li>
            <li className={curLocation.pathname ==settings.base_url + "ventas" ? "active" : "hover-ball"}>
              <Link  onClick={() =>dispatch(changeLocationAction("ventas")) + setOpen(!open) + $("html").toggleClass("overflow-hidden-mobile") + dispatch(clearPropertiesAction()) + dispatch(clearPropertyAction())}to={settings.base_url + "ventas"}>
                Ventas
              </Link>
            </li>
            <li className={"hover-ball "+ curLocation.pathname==settings.base_url+"alquileres" ? "active" : "hover-ball"}>
              <Link onClick={() => dispatch( changeLocationAction("alquileres")) + setOpen(!open) + $("html").toggleClass("overflow-hidden-mobile") + dispatch(clearPropertiesAction()) + dispatch(clearPropertyAction())} to={settings.base_url + "alquileres"}>Alquileres</Link>
            </li>
            {/* <li className={curLocation.pathname ==settings.base_url + "emprendimientos" ? "active" : "hover-ball"}>
              <Link onClick={() =>dispatch(changeLocationAction("emprendimientos"),setOpen(!open),$("html").toggleClass("overflow-hidden-mobile"))} to={settings.base_url +"emprendimientos"}>
                Emprendimientos
              </Link>
            </li> */}
            <li className={curLocation.pathname ==settings.base_url + "tasaciones" ? "active" : "hover-ball"}>
              <Link onClick={() => dispatch(changeLocationAction("tasaciones"),setOpen(!open),$("html").toggleClass("overflow-hidden-mobile"))} to={settings.base_url + "tasaciones"}>
                Tasaciones
              </Link>
            </li>
            <li className={curLocation.pathname ==settings.base_url + "nosotros" ? "active" : "hover-ball"}>
              <Link onClick={() => dispatch(changeLocationAction("nosotros"),setOpen(!open),$("html").toggleClass("overflow-hidden-mobile"))} to={settings.base_url + "nosotros"}>
                Nosotros
              </Link>
            </li>
            <li className={curLocation.pathname ==settings.base_url + "contacto" ? "active" : "hover-ball"}>
              <Link onClick={() => dispatch(changeLocationAction("contacto"), setOpen(!open),$("html").toggleClass("overflow-hidden-mobile"))} to={settings.base_url + "contacto"}>
                Contacto
              </Link>
            </li>
            <li className={curLocation.pathname ==settings.base_url + "info" ? "active" : "hover-ball"}>
              <Link onClick={() => dispatch(changeLocationAction("info"),setOpen(!open),$("html").toggleClass("overflow-hidden-mobile"))} to={settings.base_url + "info"}>
                Info útil
              </Link>
            </li>
            <li className={curLocation.pathname ==settings.base_url + "mapa" ? "active" : "hover-ball"}>
              <Link onClick={() => dispatch(changeLocationAction("mapa"),setOpen(!open),$("html").toggleClass("overflow-hidden-mobile"))} to={settings.base_url + "mapa"}>
                Mapa Cariló
              </Link>
            </li>
            <li className="iconos-redes d-flex gap-3 ">
                <a target="_blank" href={settings.social.facebook}>
                  <div className="icon-facebook1">
                    {/* <img src={FacebookIcon} alt="facebook link" /> */}
                  </div>
                </a>
                <a target="_blank"  href={settings.social.instagram}>
                  <div className="icon-instagram1">
                  {/* <img src={InstagramIcon} alt="instagram link" /> */}
                  </div>
                </a>
                {/* <a href="#">
                  <div className="twitter-icon">
                  </div>
                </a> */}
            </li>
          
            <li className="d-none d-lg-block">
              <div className="">
                <Link
                  to={settings.base_url + "favoritos"}
                  onClick={() => dispatch(changeLocationAction("favoritos"))}
                  className="favorite-button"
                >
                  {/* <span className="favorite-button"> */}
                    Favoritos
                  {/* </span> */}
                </Link>
              </div>
            </li>
          </ul>
        </nav>
        {/* {theme.location == "ventas" || theme.location == "alquileres" ? (
          <Search ></Search>
        ) : (
          ""
        )} */}
      </div>
    </header>
  );
}

export default Header;
