import { useSelector } from "react-redux";
import BreadCrumbs from "../breadcrumbs";

const Main = () => {
    const settings = useSelector((store) => store.settings);

    // Built cards with content
    const infoCard = settings.infoUtil.map( iu =>
        <div className="bg-white dark-blue p-5" key={iu.id} 
            style={{borderRadius: 40, marginBottom: "1.5rem",boxShadow:"0px 20px 60px #DBDADA"}}>
            <h1>{iu.name}</h1>
            { iu.data.map( d =>
                <div key={d.id}>
                    <p className="mb-0" style={{fontWeight: 600}}>{d.title}</p>
                    <p className="mb-0">{d.loc}</p>
                    <p className="mb-0">{d.tel}</p>
                    <p><a href={"https://"+d.web} target="_blank" >{d.web}</a></p>
                </div>
            ) }
        </div>
    );

    // Splits and order cards in columns
    const orderCol = (info) => {
        return <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    <div className="col">
                        { info.slice(0, 6) }
                    </div>
                    <div className="col">
                        { info.slice(6, 9) }
                    </div>
                    <div className="col last-col">
                        { info.slice(9, 12) }
                    </div>
                </div>
    };

    return (
        <div className="info-util">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <BreadCrumbs
                            props={[
                                { name: "Home", route: settings.base_url, location: "" },
                                {
                                    name: "Info útil",
                                    route: "",
                                    location: "",
                                    active: true,
                                },
                            ]}
                            />
                    </div>
                </div>
                <h1 className="title-1 dark-blue my-5">Información útil de&nbsp;Cariló</h1>
                { orderCol(infoCard) }
            </div>
        </div>
                
    )
}

export default Main