import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import BreadCrumbs from "./breadcrumbs"
import {formTokko,updateFields} from '../redux/contactDucks'

import { getWhatsapp } from "../functions/functions_contact";

export default function Contact(property) {
  const dispatch = useDispatch();
  const settings = useSelector((store) => store.settings);
  const [text, setText] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [datos, setDatos] = useState({
    nombre: "",
    apellido: "",
    email: "",
    text: "",
  });
  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };
  const sendForm = (event) => {
    event.preventDefault();
    dispatch(formTokko("Propiedad", property.property.fake_address, property.property.id));
    document.getElementById("formContact").reset();
    setText("¡Mensaje enviado correctamente!");
  };
  useEffect(() => {
    const update = () => {
      dispatch(updateFields(datos));
    };
    update();
  }, [datos]);
  return (
    <div className="container-fluid mt-lg-5 mancha-after2">
      <div className="row align-items-start">
        <div className="col-lg-12 contact-main pt-lg-5 px-lg-3 px-2">
          <div className="row  text-md-start">
            <div className="col-12 col-lg-6 primer-div">
              <h2 className="title-1 dark-blue" >Contactate <br className="d-lg-inline-block d-none" /> con nosotros.</h2>
            </div>
            <div className="col-12 col-lg-6 segundo-div d-none d-lg-flex">
              <div className="row ">
                <div className="col-12 col-lg-6">
                    <div className="primer-bloque-info">
                      <span style={{ fontWeight: 500 }}>
                        {settings.location.region}:
                      </span>
                      <br />
                      <br />
                      
                      <p><i className="icon-location1"></i>{settings.location.address},
                        <br /> 
                        CP: {settings.location.cp}
                      </p>

                      <p>
                        <i className="icon-celular1"></i>
                        <a href={settings.contact.phone[0]} className="footer-link" >
                          {settings.contact.phone[0]}
                        </a>
                      </p>  

                      <p><i className="icon-whatsapp"></i>
                        (+54 2254)
                        <a target="_blank" className="ms-1" href={"https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[0].link}>{settings.contact.whatsapp[0].visual}</a>
                        <div className="mx-1">/</div>
                        <a target="_blank" href={"https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[1].link}>{" " + settings.contact.whatsapp[1].visual}</a>
                      </p>
                      <p>
                        <i className="icon-ico-sendmail"></i>
                        <a className="footer-link"
                          href={"mailto:" + settings.contact.mail[0]} >
                          {settings.contact.mail[0]}
                        </a>
                      </p>

                    </div> 
                </div>
                <div className="col-12 col-lg-6">
                  <div className=" segundo-bloque-info">
                    <span style={{ fontWeight: 500 }}>
                      {settings.location2.region}:
                    </span>
                    <br />
                    <br />
                    <p><i className="icon-location1"></i> {settings.location2.address}, CP: {settings.location2.cp}</p>

                    <p><i className="icon-celular1"></i><a href={settings.contact.phone[1]} className="footer-link" >
                      {settings.contact.phone[1]}
                    </a></p>
                    <p><i className="icon-whatsapp"></i>
                      (+54 2254)
                      <a target="_blank" className="ms-1" href={"https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[0].link}>{settings.contact.whatsapp[0].visual}</a>
                      <div className="mx-1">/</div>
                      <a target="_blank" href={"https://api.whatsapp.com/send?text=%C2%A1Hola!&phone=" + settings.contact.whatsapp[1].link}>{" " + settings.contact.whatsapp[1].visual}</a></p>
                    <p> 
                        <i className="icon-ico-sendmail"></i>
                        <a
                          className="footer-link"
                          href={"mailto:" + settings.contact.mail[1]} >
                          {settings.contact.mail[1]}
                        </a>
                    </p>
                  </div> 
                </div>
              </div>
              
              
            </div>
          </div>
          <form id="formContact" onSubmit={sendForm}>
            <div className="row mt-5 mt-lg-0">
              <div className="col-lg-6 d-flex flex-column align-items-start order-1 order-lg-1 pe-lg-5">
                <div className="input-wrapper mb-3 pe-4">
                  <label>Nombre*</label>
                  <input
                    type="text"
                    name="nombre"
                    placeholder="......"
                    required
                    id=""
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-wrapper mb-3 ">
                  <label>Apellido*</label>
                  <input
                    type="text"
                    required
                    name="apellido"
                    placeholder="......"
                    id=""
                    onChange={handleInputChange}
                  />
                </div>

                <div className="w-100  ">
                  <div className="input-wrapper">
                    <label>Mail*</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="......@mail.com.ar"
                      id=""
                      required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="d-none d-lg-block">
                    <span
                      id="text-form"
                      className={(text == "" ? "d-none" : "d-block") + " mt-5"}
                    >
                      {text}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 order-3  order-lg-2 p-lg-0">
                <div className="input-wrapper h-100 mt-3 mt-lg-0">
                  <label>Mensaje*</label>
                  <textarea
                    name="text"
                    placeholder="Escribe tu mensaje aquí..."
                    id=""
                    cols="670"
                    required
                    onChange={handleInputChange}
                  ></textarea>
                </div>

                <p className="mt-0 mb-0 mb-lg-4 d-block text-start w-100 mt-2 p-obligatorio ps-3">
                  *Campos obligatorios
                </p>
                <div className="d-block d-lg-none">
                  <span
                    id="text-form"
                    className={(text == "" ? "d-none" : "d-block") + " my-4"}
                  >
                    {text}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6 order-lg-4 order-4 mt-5 pt-5 mt-lg-4">
                <div className="d-lg-flex flex-column text-center justify-content-center justify-content-md-start align-items-center align-items-md-start">
                  {settings ? (
                    <div className="px-0 mb-4">
                      <ReCAPTCHA
                        sitekey={settings.keys.captcha}
                        onChange={() => setDisabled(false)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    type="submit"
                    className="btn-rounded transparent mt-lg-0"
                    disabled={disabled}
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
