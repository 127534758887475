import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Searcher from "../propiedades/search-results";
import OwlCarousel from "react-owl-carousel2";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

import Image1 from "../../assets/images/home1.jpg";
import Image2 from "../../assets/images/home2.jpg";
import Image3 from "../../assets/images/home3.jpg";

const options = {
  items: 1,
  nav: false,
  dots: true,
  autoplay: true,
  // center: true,
  loop:true,
  autoplayTimeout: 3000,
  smartSpeed:7000,
  // autoplaySpeed: 2000,
  // autoplaySpeed:2000,
  animateIn: "fadeIn",
  animateOut: "fadeOut",
};

function Main() {
  const { data } = useSelector((store) => store.developments.development);
  const [stateSlider, setStateSlider] = useState("uno");
  const carousel = useRef(null);

  return (
    <div className="main-home">
      <div className="container-fluid">
        <div className="main">
          <div className="contenedor-header w-100">
            <div className="main-left">
              <div className=" ">
                <h1 className="title-1 ">
                  Tu mejor opción en  <br/> negocios inmobiliarios.
                </h1>
              </div>
              <Searcher />
            </div>
          </div>
        </div>
      </div>
     
      <div className="main-slider">
        <div className="background"></div>
        <OwlCarousel ref={carousel} options={options}>
          <img src={Image3} alt="" />
          <img src={Image2} alt="" />
          <img src={Image1} alt="" />
        </OwlCarousel>
      </div>

    </div>
  );
  // : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Main;

/*
  return (
    <div className={"main " + stateSlider}>
      <div className="transparencia d-block g-lg-none"></div>
      <div className="container-fluid">
        <div className="row" id="content-main">
          <div className="col-lg-4 d-flex align-items-start justify-content-lg-center flex-column">
            <h1>
              Encontrá <br />
              tu  
              {stateSlider != "dos" ? ' hogar' : ' oficina'}.
            </h1>
            <p>El estilo de vida que siempre soñaste.</p>
          </div>
        </div>

        <div className="navigator-container d-flex">
          <div className={"item-navigator cursor-pointer " + (stateSlider == "uno" ? 'active' : '')} onClick={() => setStateSlider("uno")}>
            01 <span className="line-navigator" ></span> HOGAR
          </div>
          <div className={"item-navigator cursor-pointer " + (stateSlider == "dos" ? 'active' : '')} onClick={() => setStateSlider("dos")}>
            02 <span className="line-navigator"></span> OFICINAS
          </div>
          <div className={"item-navigator cursor-pointer " + (stateSlider == "tres" ? 'active' : '')} onClick={() => setStateSlider("tres")}>
            03 <span className="line-navigator"></span> EMPRENDIMIENTOS
          </div>
        </div>
        <Searcher /> 
        </div>
        <div className="line-degrade"></div>
      </div>
    );
*/
