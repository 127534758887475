import { useRef } from "react";
import { useState, useEffect } from "react";
import Search from "../autoComplete";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { clearPropertiesAction } from "../../redux/propertiesDucks";
import { changeLocationAction } from "../../redux/themeDucks";


const search_results = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const refOperation = useRef();
  const refType = useRef();
  const refId = useRef();

  const { data } = useSelector((store) => store.properties.listFilters);
  const settings = useSelector((store) => store.settings);
  const [locations, setLocation] = useState([]);

  const [router, setRouter] = useState({
    location: "",
    type: "departamento",
    operation: "ventas",
    id:''
  });

  useEffect(() => {
    if (data) {
      for (let index = 0; index < data.objects.locations.length; index++) {
        setLocation((locations) => [
          ...locations,
          data.objects.locations[index].location_name,
        ]);
      }
    }
  }, [data]);

  const updateRoute = (value) => {
    setRouter((router) => ({
      ...router,
      [value.current.name]: value.current.value,
    }));
  };

  const goRoute = (event) => {
    event.preventDefault();
    if(router.id){
      return history.push(`code/${router.id}`);
    }
    dispatch(changeLocationAction(router.operation.toLowerCase()));
    dispatch(clearPropertiesAction());
    let routerLink =(
      settings.base_url +
          router.operation
          .toLowerCase()
          .replace(/ /g, "-") +
          "/" + 
          router.type
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "") 
          +
          (router.location !== '' ? 
            '/' + 
            router.location
            .toLowerCase()
            .replace(/ /g, "-")
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
          :'')
          // + "/" +
          // router.location
          // .toLowerCase()
          // .replace(/ /g, "-")
          // .normalize("NFD")
          // .replace(/[\u0300-\u036f]/g, "")
    );
    console.log(routerLink)
    history.push(routerLink);
  };

  const handleCode = () => {};

  return data ? (
    <div className="search-results">
      <form onSubmit={goRoute}>
        <div className="row justify-content-start">
          <div className="col-xxl-10 col-xl-11 col-lg-12">
            <div className="row">
              <div className="col-lg-11">
              <div className="filter-types">
                <div className="row gx-lg-0 align-items-center justify-content-between">
                  <div className="col-lg-3">
                    <div className="primer-div d-flex justify-content-between align-items-lg-start">
                      {/* <span className="filter-selected">Tipo de operación</span> */}
                      {/* <select
                        name="operation"
                        ref={refOperation}
                        id="operation"
                        onChange={() => updateRoute(refOperation)}
                        disabled={router.id ? true : false}
                      >
                        <option value={""} >Tipo de operación</option>
                        {data.objects.operation_types.map((item, index) =>
                          item.operation_type == 1 ? (
                            <option value={item.type}>Ventas</option>
                          ) : item.operation_type == 2 ? (
                            <option value={item.type}>Alquileres</option>
                          ) : item.operation_type == 3 ? (
                          <option value={item.type}>Alquileres Temporarios</option>
                          ) : (
                            ""
                          )
                        )}
                      </select> */}
                      <select
                        name="operation"
                        ref={refOperation}
                        id="operation"
                        onChange={() => updateRoute(refOperation)}
                      >
                        <option value="">Tipo de operación</option>
                        {data.objects.operation_types.map((item, index) =>
                          item.operation_type == 1 ? (
                            <option value={item.type}>Ventas</option>
                          ) : item.operation_type == 2 ? (
                            <option value={item.type}>Alquileres</option>
                          ) : item.operation_type == 3 ? (
                            <option value={item.type}>Alquileres temporarios</option>
                          ) : (
                            '')
                        )}
                      </select>
                      <i></i>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="primer-div border-left d-flex justify-content-between align-items-lg-start">
                      <select
                        name="type"
                        id="type"
                        ref={refType}
                        onChange={() => updateRoute(refType)}
                        disabled={router.id ? true : false}
                      >
                        <option value={""}>Tipo de propiedad</option>
                        {data.objects.property_types.map((item, index) => (
                          <option value={item.type}>{item.type}</option>
                        ))}
                      </select>
                      <i></i>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="filter-location justify-content-between">
                      <div className="code-wrapp">
                        <input
                          type="number"
                          placeholder="Cód. Propiedad"
                          className="code-input"
                          type="text"
                          name="id"
                          ref={refId}
                          onChange={() => updateRoute(refId)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="filter-location justify-content-between">
                      <Search lang={locations} disabled={router.id === '' ? false : true} updateRoute={updateRoute} />
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className="col-lg-1">
                <button className="btn-rounded  d-block h-100"><span className="icon-lupa"></span></button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  ) : (
    ""
  );
};

export default search_results;
