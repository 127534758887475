import BreadCrumbs from "../breadcrumbs";
import { useState } from "react";
import { useSelector } from "react-redux";
import TextLogo from "../../assets/images/blue-circular-text.svg";
import QuoteIcon from "../../assets/images/icons/quot-icon.svg";
import Image1 from "../../assets/images/nosotros.jpg";
import Image2 from "../../assets/images/nosotros.png";

const Main = () => {
  const [sliderStep, setSliderStep] = useState(1);
  const settings = useSelector((store) => store.settings);

  return (
    <section className="main text-center text-lg-start">
      <div className="container-fluid">
        <div className="row quote-text">
          <div className="col-12">
            <BreadCrumbs
                    props={[
                        { name: "Home", route: settings.base_url, location: "" },
                        {
                          name: "Nosotros",
                          route: "",
                          location: "",
                          active: true,
                        },
                    ]}
                    />
          </div>
          <div className="col-lg-6">
            <div className="d-lg-flex align-items-start justify-content-lg-start ">
              
              <div className="text-content">
                <h1 className="title-1 ">
                  {/* Somos un equipo experimentado <br /> en el mercado inmobiliario */}
                  “Somos un equipo <br /> experimentado <br /> en el mercado <br /> inmobiliario”
                </h1>
                {/* <h2 className="mt-5 subtitle ">Expertos desde 2005</h2> */}
                <p className="paragraph-1 m-auto m-lg-0 mb-3 ">
                  En los años acumulados a través de nuestra experiencia en el sector inmobiliario, comprendimos que esta actividad va más allá de las necesidades, expectativas, urgencias, proyectos e ilusiones del cliente, ya sea el caso de un comprador, inversor, inquilino o propietario.
                </p> 
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="image2 shadow-1">
              <div style={{ backgroundImage: `url(${Image2})` }}></div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mobile-logo position-relative">
          <div className="logo-rotation my-5">
            <img
              src={TextLogo}
              className="text"
              alt="negocios inmobiliarios"
            />
          </div>
        </div>

        <div className="row who-are-us">
          <div className="col-lg-6 order-2 order-lg-1 d-flex justify-content-start">
              <div className="image1">
                  <div style={{ backgroundImage: `url(${Image1})` }}></div>
              </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2">
              <div className="d-flex align-items-start justify-content-center justify-content-lg-start">
                  <div className="text-content">
                      {/* <h3 className="subtitle mt-5 ">¿Quiénes somos?</h3> */}
                      <p className="paragraph-1 ">
                        Observamos que además de la particular motivación de cada persona, el cliente debe ser acompañado por profesionales que cuenten con los recursos, los medios, la capacidad y eficiencia para brindar la mejor solución a su búsqueda.
                      </p>
                      {/* <h3 className="subtitle-2 mt-4 mb-5 ">Confiar en nosotros es confiar en la experiencia.</h3> */}
                      <p className="paragraph-1"> Nos apasiona acercar soluciones a las personas y acompañarlos con la mayor calidez, humildad y afecto posible.</p>
                      <h3 className="subtitle-2 mt-4 mb-5 "> Equipo de la Compañía Cariló</h3>
                  </div>
              </div>
          </div>
      </div>
        </div>
    </section>
  );
};

export default Main;
