import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {shortDirection} from '../../functions/functions_property'
import {changeLocationAction} from '../../redux/themeDucks'

const Lotes = () => {
  const settings = useSelector(store => store.settings)
  const dispatch = useDispatch()
  const { data } = useSelector((store) => store.developments.units_development);

  return data ? (
    <section className="lotes">
      <div className="container-fluid">
        <h2 className="title-1 mb-0 mt-4 text-centers text-md-start">Descripción de unidades.</h2>

        <div className="table-wrapper">
          <table className="mt-5">
            <tr>
              <th>Piso</th>
              <th className="text-center">Superficie</th>
              <th className="text-center">Valor</th>
              <th></th>
            </tr>

            {data.objects.map((item, index) => (
              <tr className="underlined">
                <td>{item.publication_title}</td>
                <td className="text-center">
                  {item.surface} {item.surface_measurement}
                </td>
                <td className="text-center" style={{ width: "200px" }}>
                  {item.operations[0].prices[0].currency}{" "}
                  {new Intl.NumberFormat("de-DE").format(
                    item.operations[0].prices[0].price
                  )}{" "}
                </td>
                <td className="text-end" style={{ width: "10px" }}>
                  <Link to={settings.base_url + "propiedad/" + shortDirection(item.operations[0].operation_type , item.type.name, item.location.name , item.id , item.fake_address)} onClick={() => dispatch(changeLocationAction("propiedad"))} className="btn-medium-dark">
                    <i className="icon-arrow"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </section>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
};

export default Lotes;
